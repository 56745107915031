import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, Redirect, useLocation } from 'react-router-dom';
import { getUser } from '../../Profile/redux/actions';
import { getSetting } from '../../Hubs/dochub/redux/actions';
import { toggleAssistant } from '../../../redux/actions';
import { useMediaQuery, Hidden, Tooltip, AppBar, Toolbar, IconButton, CssBaseline, Typography } from '@mui/material';
import { Avatar, Popover, List, ListItemIcon, ListItemButton } from '@mui/material';
import { Badge, ListItemText, Grid, Portal } from '@mui/material';
import { useTheme } from '@mui/material';

import { DateRange, Apps, AvTimerOutlined, PaletteRounded, StarBorderRounded } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import QHubLogo from '../../../Images/logos/main-logo.png';
import useNotifications from '../Notifications/useNotifications';
import useChatNotifications from '../Notifications/useChatNotifications';
import moment from 'moment';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ChatIcon from '@mui/icons-material/Chat';
import Chats from '../../Chats/chats';
import StateManager from '../StateManager';
import NewTask from '../../Hubs/tasks/newTask';
import Task from '../Components/Task';
import AlertBox from '../../Global/Alert';
import { subscribeUserToPush } from '../../Chats/push';
import { TaskIcon, FormIcon, DocIcon, KPIHubIcon, ResourcesIcon, AuditIcon } from '../../Global/Icons';
import { ProcessIcon, AssetIcon, PortalIcon, RegistersIcon } from '../../Global/Icons';
import SupportDialog from '../../Support/components/SupportDialog';
import FaceIcon from '@mui/icons-material/Face';
import { blue, amber } from '@mui/material/colors';
import TimerManager from '../../Global/TimerManager';
import UsersDialog from '../UsersDialog';
import SelectUserDialog from '../SelectUserDialog';
import ComfirmDialog from '../ConfirmDialog';
import ReviewDialog from '../../Hubs/dochub/components/ReviewDialog';
import Timers, { PauseDialog } from './Timers';
import Profile from './Profile';
import ChatNotifications from './ChatNotifications';
import Notifications from './Notifications';
import { styled } from '@mui/material/styles';
import GlobalSearch from '../GlobalSearch';
import ThemePicker from './ThemePicker';
import { closeRuDialog } from '../../Hubs/dochub/redux/actions/ru';
import ReadUnderstoodDialog from '../ReadUnderstoodDialog';
import FormEntryDialog from '../../Hubs/forms/components/FormEntryDialog';
import QhubAccessDialog from './QhubAccessDialog';
import { ExternalPdfViewerDialog } from '../FilePreviewer/pdf-external';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#87041a',
    color: '#87041a',
    boxShadow: `0 0 0 2px ${blue[500]}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const RedBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#87041a',
  },
}));

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  width: 200,
  padding: theme.spacing(2, 3),
}));

const StyledToolbar = styled(Toolbar)({
  minHeight: 54,
});

export function Hubs({ open, anchorEl, onClose }) {
  const { user } = useSelector(({ profile }) => profile);
  const available = user?.company?.availableHubs || [];
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Grid container>
        <List>
          <Link to="/tasks/my">
            <StyledListItem>
              <ListItemIcon>
                <TaskIcon />
              </ListItemIcon>
              <ListItemText disableTypography primary={<Typography color={'textPrimary'}>Work Hub</Typography>} />
            </StyledListItem>
          </Link>
          <Link to="/forms/home">
            <StyledListItem>
              <ListItemIcon>
                <FormIcon />
              </ListItemIcon>
              <ListItemText disableTypography primary={<Typography color={'textPrimary'}>Form Hub</Typography>} />
            </StyledListItem>
          </Link>
          <Link to="/processes/home">
            <StyledListItem>
              <ListItemIcon>
                <ProcessIcon />
              </ListItemIcon>
              <ListItemText disableTypography primary={<Typography color={'textPrimary'}>Process Hub</Typography>} />
            </StyledListItem>
          </Link>
          <Link to="/DocLibrary/home">
            <StyledListItem>
              <ListItemIcon>
                <DocIcon />
              </ListItemIcon>
              <ListItemText disableTypography primary={<Typography color={'textPrimary'}>Doc Library</Typography>} />
            </StyledListItem>
          </Link>

          <Hidden smDown>
            <Link to={'/kpiHub/home'}>
              <StyledListItem>
                <ListItemIcon>
                  <KPIHubIcon />
                </ListItemIcon>
                <ListItemText disableTypography primary={<Typography color={'textPrimary'}>KPI Hub</Typography>} />
              </StyledListItem>
            </Link>
          </Hidden>
          {available?.includes('register') && (
            <Link to="/registers/home">
              <StyledListItem>
                <ListItemIcon>
                  <RegistersIcon />
                </ListItemIcon>
                <ListItemText disableTypography primary={<Typography color={'textPrimary'}>Register Hub</Typography>} />
              </StyledListItem>
            </Link>
          )}
        </List>
        <List>
          <Hidden smDown>
            {available?.includes('portal') && (
              <Link to="/portal/management">
                <StyledListItem>
                  <ListItemIcon>
                    <PortalIcon />
                  </ListItemIcon>
                  <ListItemText disableTypography primary={<Typography color={'textPrimary'}>Portal</Typography>} />
                </StyledListItem>
              </Link>
            )}
          </Hidden>
          {available?.includes('resources') && (
            <Link to="/resources">
              <StyledListItem>
                <ListItemIcon>
                  <ResourcesIcon />
                </ListItemIcon>
                <ListItemText disableTypography primary={<Typography color={'textPrimary'}>Resources</Typography>} />
              </StyledListItem>
            </Link>
          )}
          {available?.includes('audit') && (
            <Link to="/audits">
              <StyledListItem>
                <ListItemIcon>
                  <AuditIcon />
                </ListItemIcon>
                <ListItemText disableTypography primary={<Typography color={'textPrimary'}>Audit Hub</Typography>} />
              </StyledListItem>
            </Link>
          )}
          {available?.includes('asset') && (
            <Link to="/asset/home">
              <StyledListItem>
                <ListItemIcon>
                  <AssetIcon />
                </ListItemIcon>
                <ListItemText disableTypography primary={<Typography color={'textPrimary'}>Asset Hub</Typography>} />
              </StyledListItem>
            </Link>
          )}
        </List>
      </Grid>
    </Popover>
  );
}

function BackButton() {
  const history = useHistory();
  return (
    <IconButton onClick={() => history.goBack()}>
      <ArrowBack />
    </IconButton>
  );
}

export default function AppHeader({ back = false, ...props }) {
  const dispatch = useDispatch();
  const { user } = useSelector(({ profile }) => profile);
  const { ruDialog } = useSelector(({ ru }) => ru);
  const theme = useTheme();
  const history = useHistory();
  const timer = useRef();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const isPortalUser = user && user.access === 'portal';
  const location = useLocation();

  const [anchorElHubs, setAnchorElHubs] = useState();
  const [anchorElProfile, setAnchorElProfile] = useState();
  const [anchorElNotifications, setAnchorElNotifications] = useState();
  const [anchorElChats, setAnchorElChats] = useState();
  const [anchorElTheme, setAnchorElTheme] = useState();
  const { notifications, read, clear, refresh, unread } = useNotifications(props.hideNotificationIcon);
  const { chatNotifications } = useChatNotifications(false);
  const [badgeContent, setBadgeContent] = useState(0);
  const [chatOpen, setChatOpen] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const [taskParams, setTaskParams] = useState(null);

  const [taskOpen, setTaskOpen] = useState(false);
  const [supportOpen, setSupportOpen] = useState(false);
  const [timersAnchor, setTimersAnchor] = useState();
  const [timers, setTimers] = useState([]);
  const [confirmTaskId, setConfirmTaskId] = useState();
  const [runningTasks, setRunningTasks] = useState([]);
  const [selectUser, setSelectUser] = useState();
  const [selectUserParams, setSelectUserParams] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmParams, setConfirmParams] = useState({});

  const [selectReviewDialog, setSelectReviewDialog] = useState(false);
  const [reviewDialogParams, setReviewDialogParams] = useState({});

  // pdf
  const [pdfDialog, setPdfDialog] = useState(false);
  const [pdfParams, setPdfParams] = useState({});

  const [qhubAccessDialog, setQhubAccessDialog] = useState(false);
  const [qhubAccessRequestId, setQhubAccessRequestId] = useState(false);

  const [formDialog, setFormDialog] = useState(false);
  const [formParams, setFormParams] = useState({});

  // new tasks
  const [newTaskOpen, setNewTaskOpen] = useState(false);
  const [newTaskParams, setNewTaskParams] = useState({});

  // alert bar
  const [alertType, setAlertType] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  const [usersDialog, setUsersDialog] = useState(false);
  const [userDialogParams, setUserDialogParams] = useState(null);

  const disableChat = user?.disableChat || false;

  function onUsersDialogClose(res) {
    if (res && userDialogParams?.onResult) {
      userDialogParams.onResult(res);
    }
    setUserDialogParams(null);
    setUsersDialog(false);
  }

  function closeReviewDialog() {
    setSelectReviewDialog(false);
  }

  function closeSelectUser(user) {
    setSelectUser(false);
    if (user?._id) {
      if (selectUserParams.onSelect) selectUserParams.onSelect(user);
    } else {
      if (selectUserParams.onCancel) selectUserParams.onCancel(user);
    }
    selectUserParams.title = null;
  }

  function confirmPause() {
    TimerManager.startTaskTimerAndStopOthers(confirmTaskId);
    setConfirmTaskId(null);
  }

  function cancelPause() {
    TimerManager.startTaskTimer(confirmTaskId);
    setConfirmTaskId(null);
  }

  function setAlert(alert) {
    setAlertType(alert.type);
    setAlertMessage(alert.message);
  }

  useEffect(() => {
    // detect that app has been updated and update the push subscription
    if (localStorage.getItem('appUpdated') === 'true' && localStorage.getItem('pushSubscription')) {
      setTimeout(() => {
        subscribeUserToPush();
        // localStorage.removeItem('appUpdated');
      }, 20 * 1000); // because it takes a while to register SW
    }
    StateManager.subscribeToChat((user) => {
      if (typeof user === 'string') {
        setChatOpen(user);
      } else {
        setSelectedUser(user);
        setChatOpen(true);
      }
    });
    StateManager.subscribeToNewTask(({ params, onResult }) => {
      setNewTaskOpen(true);
      setNewTaskParams({ params, onResult });
    });
    StateManager.subscribeToTask(({ taskId, onResult, onDelete, onMovingToBacklog }) => {
      setTaskParams({ taskId, onResult, onDelete, onMovingToBacklog });
      setTaskOpen(true);
    });
    StateManager.subscribeToAlert(setAlert);
    setTimers(TimerManager.getTimers());
    TimerManager.subscribeToTimers(setTimers);
    TimerManager.subscribeToConfirm((params) => {
      setConfirmTaskId(params.taskId);
      setRunningTasks(params.running);
    });

    StateManager.subscribeToSelectUser((params) => {
      setSelectUser(true);
      setSelectUserParams(params);
    });
    StateManager.subscribeToUsersDialog((params) => {
      setUsersDialog(true);
      setUserDialogParams(params);
    });
    StateManager.subscribeToConfirm((params) => {
      setConfirmOpen(true);
      setConfirmParams(params);
    });

    StateManager.subscribeToReviewDialog((params) => {
      setSelectReviewDialog(true);
      setReviewDialogParams(params);
    });

    StateManager.subscribeToQhubAccessDialog(({ requestId }) => {
      setQhubAccessDialog(true);
      setQhubAccessRequestId(requestId);
    });

    StateManager.subscribeToFormEntry((params) => {
      setFormDialog(true);
      setFormParams({
        entryId: params.entryId,
        onFormComplete: params.onFormComplete,
        onFormDelete: params.onFormDelete,
      });
    });

    StateManager.subscribeToPdfDialog((params) => {
      setPdfDialog(true);
      setPdfParams({
        url: params?.url,
        title: params?.title,
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (timers.length === 0) {
      setTimersAnchor(null);
    }
  }, [timers]);

  useEffect(() => {
    document.title = props.title;
    dispatch(getUser());
    dispatch(getSetting());
  }, [props.title, dispatch]);

  useEffect(() => {
    if (!notifications) return;
    const unreadCount = notifications.filter((x) => !x.readAt).length;
    setBadgeContent(unreadCount);
    if (unreadCount > 0) document.title = `(${unreadCount}) ${props.title}`;
    else document.title = props.title;
  }, [notifications]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" elevation={0} sx={theme.palette.mode === 'dark' ? { backgroundColor: '#0F192A' } : {}}>
        <StyledToolbar variant="dense" className="denseHeader">
          <Grid
            container
            justifyContent={isPortalUser && location.pathname === '/home' ? 'space-between' : 'flex-start'}
          >
            {(location.pathname !== '/home' || !isPortalUser) && (
              <Grid container item lg={4} md={4} sm={4} xs={4} alignItems="center">
                {!largeDevices &&
                  location.pathname !== '/home' &&
                  (back ? (
                    <BackButton />
                  ) : (
                    <Link to={isPortalUser ? '/portal' : '/home'}>
                      {user && (
                        <img
                          alt="img"
                          src={user?.company?.logo && user?.company?.useAsLogo ? user.company.logo : QHubLogo}
                          style={{ height: 42, maxHeight: 42, maxWidth: 150, verticalAlign: 'middle' }}
                        />
                      )}
                    </Link>
                  ))}
                {!isPortalUser && (largeDevices || location.pathname === '/home') && (
                  <IconButton
                    edge="start"
                    style={{ marginRight: 16 }}
                    onClick={(event) =>
                      (largeDevices || location.pathname === '/home') && setAnchorElHubs(event.currentTarget)
                    }
                  >
                    <Apps style={{ color: 'white' }} />
                  </IconButton>
                )}
                <Hubs
                  available={user?.company?.availableHubs || []}
                  open={Boolean(anchorElHubs)}
                  anchorEl={anchorElHubs}
                  onClose={() => setAnchorElHubs(null)}
                />
                {largeDevices && (
                  <Link to={isPortalUser ? '/portal' : '/home'}>
                    {user && (
                      <img
                        alt="img"
                        src={user?.company?.logo && user?.company?.useAsLogo ? user.company.logo : QHubLogo}
                        style={{ height: 42, maxHeight: 42, maxWidth: 150, verticalAlign: 'middle' }}
                      />
                    )}
                  </Link>
                )}
              </Grid>
            )}

            <Grid
              container
              item
              lg={4}
              md={4}
              sm={4}
              xs={4}
              alignItems="center"
              justifyContent="center"
              style={{
                marginLeft: isPortalUser && location.pathname === '/home' && largeDevices ? 'auto' : 'inherit',
              }}
            >
              {largeDevices && <GlobalSearch />}
            </Grid>

            <Grid container item lg={4} md={4} sm={4} xs={4} alignItems="center" justifyContent="flex-end">
              {false && (
                <Hidden smDown>
                  {!isPortalUser && (
                    <Tooltip title="App Assistant">
                      <IconButton onClick={() => dispatch(toggleAssistant(true))}>
                        <FaceIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Hidden>
              )}
              {timers.length > 0 && (
                <IconButton onClick={(e) => setTimersAnchor(e.currentTarget)}>
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    variant="dot"
                    invisible={timers.findIndex((t) => t.state === 'active') === -1}
                  >
                    <AvTimerOutlined style={{ color: 'white' }} />
                  </StyledBadge>
                </IconButton>
              )}
              <Timers
                open={Boolean(timersAnchor)}
                anchorEl={timersAnchor}
                onClose={() => setTimersAnchor(null)}
                timers={timers}
              />

              <Hidden smDown>
                {!isPortalUser && (
                  <Tooltip title="Notice board">
                    <IconButton onClick={() => history.push('/noticeBoard')}>
                      <DateRange style={{ color: 'white' }} />
                    </IconButton>
                  </Tooltip>
                )}
                {!disableChat && (
                  <Tooltip title="Chats">
                    <IconButton
                      onClick={(event) => {
                        if (chatNotifications.length === 0) {
                          setChatOpen(true);
                        } else {
                          setAnchorElChats(event.currentTarget);
                        }
                      }}
                    >
                      <div style={{ marginTop: '-6px' }}>
                        <RedBadge
                          badgeContent={chatNotifications.length}
                          color="error"
                          invisible={chatNotifications.length === 0}
                        >
                          <ChatIcon style={{ color: 'white' }} />
                        </RedBadge>
                      </div>
                    </IconButton>
                  </Tooltip>
                )}

                <ChatNotifications
                  open={Boolean(anchorElChats)}
                  anchorEl={anchorElChats}
                  onClose={() => setAnchorElChats(null)}
                  notifications={chatNotifications}
                  openChats={(value) => {
                    setChatOpen(value);
                    setAnchorElChats(null);
                  }}
                />
                {!props.hideNotificationIcon && (
                  <Tooltip title="Notifications">
                    <IconButton onClick={(event) => setAnchorElNotifications(event.currentTarget)}>
                      <RedBadge badgeContent={badgeContent} color="error" invisible={badgeContent === 0}>
                        <NotificationsIcon style={{ color: 'white' }} />
                      </RedBadge>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="App theme">
                  <IconButton onClick={(event) => setAnchorElTheme(event.currentTarget)}>
                    <PaletteRounded style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
                {!props.hideNotificationIcon && (
                  <Notifications
                    open={Boolean(anchorElNotifications)}
                    anchorEl={anchorElNotifications}
                    onClose={() => {
                      refresh();
                      setAnchorElNotifications(null);
                    }}
                    notifications={notifications}
                    read={read}
                    clear={clear}
                    unread={unread}
                    hideButton={true}
                  />
                )}
              </Hidden>
              <Hidden smUp>
                <RedBadge
                  badgeContent={badgeContent + chatNotifications.length}
                  color="error"
                  invisible={badgeContent + chatNotifications.length === 0}
                >
                  <Avatar
                    src={user && user.picture}
                    style={{ background: user?.avatarColor, cursor: 'pointer', marginLeft: 16 }}
                    onClick={(event) => setAnchorElProfile(event.currentTarget)}
                  >
                    {user?.avatarLetters}
                  </Avatar>
                </RedBadge>
              </Hidden>
              <Hidden smDown>
                <Avatar
                  src={user && user.picture}
                  style={{ background: user?.avatarColor, cursor: 'pointer', marginLeft: 16 }}
                  onClick={(event) => setAnchorElProfile(event.currentTarget)}
                >
                  {user?.avatarLetters}
                </Avatar>
              </Hidden>
              <Profile
                open={Boolean(anchorElProfile)}
                anchorEl={anchorElProfile}
                onClose={() => setAnchorElProfile(null)}
                badgeContent={badgeContent}
                unreadChats={chatNotifications.length}
                openChats={() => setChatOpen(true)}
                openSupport={() => {
                  setSupportOpen(true);
                  setAnchorElProfile(null);
                }}
              />
            </Grid>
            {!disableChat && (
              <Chats
                open={Boolean(chatOpen)}
                onClose={() => setChatOpen(false)}
                selectedChat={chatOpen}
                selectedUser={selectedUser}
              />
            )}
            {newTaskOpen && (
              <NewTask
                open={Boolean(newTaskOpen)}
                onClose={() => setNewTaskOpen(false)}
                onResult={newTaskParams.onResult}
                {...newTaskParams.params}
              />
            )}
            <Task
              id={taskParams?.taskId}
              open={taskOpen}
              onClose={() => setTaskOpen(false)}
              onSelect={(taskId) => setTaskParams({ taskId })}
              onChange={taskParams?.onResult}
              onDelete={taskParams?.onDelete}
              onMovingToBacklog={taskParams?.onMovingToBacklog}
            />
            <SupportDialog open={Boolean(supportOpen)} onClose={() => setSupportOpen(false)} />
            <PauseDialog
              open={Boolean(confirmTaskId)}
              onClose={cancelPause}
              running={runningTasks}
              onConfirm={confirmPause}
            />
            <ComfirmDialog
              open={Boolean(confirmOpen)}
              onClose={() => {
                setConfirmOpen(false);
                setConfirmParams({});
              }}
              title={confirmParams.title}
              content={confirmParams.content}
              onConfirm={confirmParams.onConfirm}
            />
            <SelectUserDialog open={Boolean(selectUser)} {...selectUserParams} onClose={closeSelectUser} />
            <ThemePicker
              anchorEl={anchorElTheme}
              open={Boolean(anchorElTheme)}
              onClose={() => setAnchorElTheme(null)}
            />
            <UsersDialog open={usersDialog} onClose={onUsersDialogClose} {...userDialogParams} />
            <ReviewDialog
              reviewId={reviewDialogParams?.reviewId}
              setup={Boolean(selectReviewDialog)}
              handleSetup={closeReviewDialog}
            />

            <FormEntryDialog
              open={formDialog}
              entryId={formParams?.entryId}
              onClose={() => {
                setFormDialog(false);
                setFormParams({});
              }}
              onFormComplete={formParams?.onFormComplete}
              onFormDelete={formParams?.onFormDelete}
            />
            <ReadUnderstoodDialog open={Boolean(ruDialog)} handleClose={() => dispatch(closeRuDialog())} />

            <QhubAccessDialog
              open={qhubAccessDialog}
              onClose={() => setQhubAccessDialog(false)}
              requestId={qhubAccessRequestId}
            />

            <ExternalPdfViewerDialog
              open={pdfDialog}
              onClose={() => {
                setPdfDialog(false);
                setPdfParams({});
              }}
              url={pdfParams?.url}
              title={pdfParams?.title}
            />

            {/* wraped in Portal to ensure alerts are above dialogs */}
            <Portal>
              <AlertBox
                open={Boolean(alertMessage)}
                handleClose={() => setAlertMessage('')}
                type={alertType}
                message={alertMessage}
                duration={5000}
              />
            </Portal>
            {user &&
              user.company &&
              !user.company.isPremiumUser &&
              moment(user.company.endTrial).diff(moment(), 'hours') < 0 && <Redirect to="/expired" />}
            {user && user.company && user.company.isPremiumUser && user.company.subscriptionStatus === 'canceled' && (
              <Redirect to="/suspended" />
            )}
            {user && user.company && user.company.archived && <Redirect to="/suspended" />}
            {user?.passwordChangeRequired && (
              <Redirect to={`/change-password/${user._id}?token=${localStorage.getItem('token')}`} />
            )}
          </Grid>
        </StyledToolbar>
      </AppBar>
    </>
  );
}
