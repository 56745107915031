import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { isArray } from 'lodash';
import {
  isReviewOverdue,
  getReviewStatus,
  formatRevision,
  downloadFile,
  DOCUMENT_STATUS,
  COLORS,
  formatDate,
} from '../../constants';
import {
  getTags,
  setDocDrawer,
  setSelectedDoc,
  getLinkedFiles,
  updateDocument,
  fetchComments,
  addComment,
  likeComment,
  updateComment,
  deleteComment,
  openSetupDialog,
  updateDocumentAndDownload,
  generatePdf,
  addLink,
} from '../../redux/actions';
import { getDecryptedFile, previewFile } from '../../redux/actions/file';
import { updateOwner } from '../../redux/actions/document';
import { getReviews, startReview, getReviewByDocument } from '../../redux/actions/review';
import { createReadUnderstood } from '../../redux/actions/ru';
import { getMajorRevisions, getMiniRevisions, setIssueDoc, addIssue } from '../../redux/actions/revision';

import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import {
  User,
  UserGroup,
  DatePicker,
  Document,
  TooltipIconButton,
  TooltipTypography,
} from '../../../../Global/Components';
import StateManager from '../../../../Global/StateManager';
import { ReadUnderstoodIcon, DocIcon } from '../../../../Global/Icons';
import InputField from '../InputField';
import AutoSelection from '../Autocomplete';

import IssueInfo from '../RevisionInfoDialog/mini';
import RevisionInfoDialog from '../RevisionInfoDialog';
import CommentsSection from '../Comments';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { CircularProgress, Chip, Paper } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import ChatIcon from '@mui/icons-material/Chat';
import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit';
import EditIconOutlined from '@mui/icons-material/EditOutlined';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import DoneIcon from '@mui/icons-material/Done';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PublishIcon from '@mui/icons-material/Publish';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ShareIcon from '@mui/icons-material/Share';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ShareMenu from '../RightMenu/share-menu';
import SendRUTaskDialog from '../SendRUTaskDialog';
import useDocumentName from '../../hooks/useDocumentName';
import UseConventionSetDialog from '../UseConventionSetDialog';
import ChangeOwnerDialog from '../ChangeOwnerDialog';
import TagsDialog from '../Tags';
import { setTableKey } from '../../redux/actions/draft';
import DocumentReviewersApproversDialog from '../DocumentReviewersApproversDialog';
import RefLinkDialog from '../HTMLEditor/components/RefLinkDialog';

const Panel = ({ title, icon, id, expanded, setExpanded, children, button, enableChip, chipContent, chipColor }) => {
  return (
    <Accordion
      expanded={expanded}
      onChange={setExpanded(id)}
      elevation={0}
      sx={{
        width: '100%',
        border: '1px solid #f6f6f6',
        ...(expanded && {
          margin: '25px 0px',
          borderBottom: 0,
        }),
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
        sx={{
          alignItems: 'center',
          '& .MuiAccordionSummary-content': {
            alignItems: 'center',
          },
        }}
      >
        {icon}
        <Typography sx={{ fontSize: (theme) => theme.typography.pxToRem(15), marginLeft: '15px' }}>{title}</Typography>
        {enableChip && !chipContent && <Box sx={{ marginLeft: 'auto' }}></Box>}
        {enableChip && chipContent && (
          <Chip
            sx={{
              backgroundColor: chipColor,
              color: 'white',
              marginLeft: 'auto',
              alignItems: 'center',
              fontWeight: 'bold',
            }}
            label={chipContent}
            size="small"
          />
        )}
        {expanded && button && (
          <Box sx={{ marginLeft: enableChip ? '5px' : 'auto', alignItems: 'center' }}>{button}</Box>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ margin: '20px 0px' }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default function DocumentDrawer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tags, conventionSets, documentSharing, ruPageAccess, ruAccess } = useSelector(({ settings }) => settings);
  const { sets } = useSelector(({ sets }) => sets);
  const {
    docDrawer,
    refetchTable,
    selected,
    linkedFiles,
    revisionFiles,
    comments,
    fetching,
    miniRevisions,
    userAreaSettings,
    updatingDocument,
    documentLoading,
  } = useSelector(({ dochub }) => dochub);
  const { user } = useSelector(({ profile }) => profile);
  const { reviews, reviewStarting } = useSelector(({ review }) => review);

  const cantChangeOwner =
    user?.access !== 'admin'
      ? user?._id !== selected?.owner_id?._id ||
        selected?.locked ||
        ['In Work', 'In Review', 'Waiting Approval', 'Under Review', 'Pre-approval'].includes(selected?.documentState)
      : false;

  const [ownerDialog, setOwnerDialog] = useState(false);
  const [ownerUpdating, setOwnerUpdating] = useState(false);

  const [openRuDIalog, setOpenRuDialog] = useState(false);

  const [selectedVersion, setSelectedVersion] = useState(null);

  const [shareMenu, setShareMenu] = useState(null);

  const [title, setTitle] = useState('');
  const [expanded, setExpanded] = useState('docInfo');
  const [editTags, setEditTags] = useState(false);
  const [editTitle, setEditTitle] = useState(false);

  const [issue, setIssue] = useState(false);
  const [issueDetails, setIssueDetails] = useState(null);

  const [refDialog, setRefDialog] = useState(false);

  const [revision, setRevision] = useState();
  const [revisionInfoDialog, setRevisionInfoDialog] = useState(false);

  const [reviewerApproverDialog, setReviewerApproverDialog] = useState(false);

  const [editDate, setEditDate] = useState(false);
  const [reviewDate, setReviewDate] = useState();
  const [inProgressReview, setInProgressReview] = useState(null);

  const [conventionDialog, setConventionDialog] = useState(false);
  const [downloadTrigger, setDownloadTrigger] = useState(false);
  const [issuing, setIssuing] = useState(false);

  const documentName = useDocumentName({
    title,
    sequence: selected?.sequence,
    setId: selected?.conventionSet,
    initial: selected?.conventionSetParams,
  });

  useEffect(() => {
    if (!docDrawer) return;
    dispatch(getTags());
  }, [dispatch, docDrawer]);

  useEffect(() => {
    if (!docDrawer) return;
    if (selected !== null) {
      batch(() => {
        dispatch(getReviews(selected?._id));
        dispatch(getLinkedFiles({ sourceDocument: selected._id, status: 'published' }));
        dispatch(getMajorRevisions(selected._id));
        dispatch(fetchComments(selected._id));
        getReviewByDocument(selected._id, setInProgressReview);
        setReviewDate(selected.reviewDate || null);
      });
      if (selected.title) {
        setTitle(selected.title);
      }
    }
  }, [dispatch, selected, docDrawer]);

  const handleChange = (panel) => (event, isExpanded) => {
    if (event.target.id === 'review' || event.target.id === 'upissue' || event.target.id === 'link') return;
    setExpanded(isExpanded ? panel : false);
  };
  const handleClose = () => {
    if (refetchTable) {
      refetchTable(true);
    }
    dispatch(setDocDrawer(false));
  };

  const getIconStyles = (id) => ({ color: expanded === id ? COLORS.SEARCH : COLORS.UPLOAD });

  const createIssue = () => {
    let data = {
      ...selected,
      title: documentName,
      issueDate: moment().toISOString(),
      lastModified: moment().toISOString(),
      reviewDate: selected.reviewDate,
      revisionReason: '',
      revisionChanges: '',
      document: selected._id,
      upissuing: true,
    };
    if (!selected.manualIssueControl) {
      data.issue = selected.issue + 1;
    }
    if (selected.isFolder) {
      data.folder = selected.folder._id;
    }
    if (user?.access === 'view' && user?._id !== selected?.owner_id?._id) {
      return;
    }
    dispatch(setTableKey(null, false));
    dispatch(addIssue(data, history));
    setIssuing(false);
    handleClose();
  };

  const handleOwnerChange = (owner) => {
    setOwnerUpdating(true);
    dispatch(
      updateOwner(selected._id, owner, () => {
        setOwnerUpdating(false);
        setOwnerDialog(false);
      }),
    );
  };

  const handleIssue = () => {
    const area = selected?.area?._id;
    const areaOwner = selected?.area?.created_by;
    const isAreaOwner = areaOwner === user?._id;
    const isAppAdmin = user?.access === 'admin';
    const setting = userAreaSettings?.find((x) => x.area === area);
    const isAreaAdmin = setting?.access === 'full' && setting?.approved;
    const hasNonAdminAccess = setting?.access !== 'full' && setting?.approved;
    const isDocOwner = selected?.owner_id?._id === user?._id;
    const isAreaAccessUser = hasNonAdminAccess && isDocOwner;
    const isPermissible = isDocOwner || isAreaOwner || isAppAdmin || isAreaAdmin || isAreaAccessUser;
    if (isPermissible) {
      if (conventionSets) {
        if (selected?.conventionSet) {
          const set = sets.find((set) => set._id === selected?.conventionSet);
          const staticFields = set?.fields.filter((field) => ['autonumber', 'text'].includes(field.fieldType));
          if (staticFields?.length === set?.fields?.length) {
            createIssue();
          }
          const filteredFields = set?.fields?.filter((field) =>
            ['textArea', 'number', 'dropbox'].includes(field.fieldType),
          );
          if (
            filteredFields?.length === selected?.conventionSetParams?.length &&
            selected?.conventionSetParams.every((x) => x.value !== '')
          ) {
            createIssue();
          } else {
            setIssuing(true);
            setDownloadTrigger(false);
            setConventionDialog(true);
          }
        } else {
          createIssue();
        }
      } else {
        createIssue();
      }
    } else {
      StateManager.setErrorAlert('You do not have access to perform this action');
    }
  };

  const onReviewDateEdit = () => {
    const area = selected?.area?._id;
    const areaOwner = selected?.area?.created_by;
    const isAreaOwner = areaOwner === user?._id;
    const isAppAdmin = user?.access === 'admin';
    const setting = userAreaSettings?.find((x) => x.area === area);
    const isAreaAdmin = setting?.access === 'full' && setting?.approved;
    const hasNonAdminAccess = setting?.access !== 'full' && setting?.approved;
    const isDocOwner = selected?.owner?._id === user?._id;
    const isAreaAccessUser = hasNonAdminAccess && isDocOwner;
    const isPermissible = isDocOwner || isAreaOwner || isAppAdmin || isAreaAdmin || isAreaAccessUser;
    if (isPermissible) {
      setReviewDate(selected.reviewDate);
      setEditDate(true);
    } else {
      StateManager.setErrorAlert('You do not have access to perform this action');
    }
  };

  const isTagsAvailable = () => {
    const area = selected?.area?._id;
    const setting = userAreaSettings?.find((x) => x.area === area);
    if (user?.access === 'admin' || selected?.created_by === user?._id || setting?.access === 'full') {
      return true;
    }
    return false;
  };

  const showIssueAlert = () => {
    StateManager.setConfirm(
      'Review in Progress',
      () => {},
      'You can not up issue this document, a review is in progress',
    );
  };

  const showReviewConfirmation = () => {
    StateManager.setConfirm(
      'Review Document',
      onReview,
      'You are about to start the review process. It would start a workflow task for you.',
    );
  };

  const onReview = () => {
    dispatch(
      startReview(selected?._id, (e) => {
        if (e) {
          StateManager.openReviewDialog(e);
          getReviewByDocument(selected._id, setInProgressReview);
        }
      }),
    );
  };

  const buttonOptions = [
    {
      name: 'Up Issue',
      icon: <PublishIcon />,
      action: () =>
        reviews?.length > 0 && Boolean(reviews?.find((r) => r.status !== 'Completed'))
          ? showIssueAlert()
          : handleIssue(),
      props: {
        disabled:
          (user && user.access === 'view' && selected?.owner_id?._id !== user?._id) ||
          (revisionFiles && revisionFiles.length > 0 && Boolean(revisionFiles.find((r) => r.upissuing))) ||
          Boolean(selected && selected.locked),
        id: 'upissue',
      },
    },
    {
      name: reviewStarting ? 'Reviewing' : 'Review now',
      icon: reviewStarting ? <CircularProgress size={20} /> : <FeedbackIcon />,
      action: () => showReviewConfirmation(),
      props: {
        disabled:
          (reviews && reviews.length > 0 && Boolean(reviews.find((r) => !r.completed))) ||
          (user && user.access === 'view') ||
          (revisionFiles && revisionFiles.length > 0 && Boolean(revisionFiles.find((r) => r.upissuing))),
        id: 'review',
      },
    },
    {
      name: 'New Link',
      icon: <AddIcon />,
      action: () => setRefDialog(true),
      props: { id: 'link' },
    },
  ];

  const openFile = (selected) => {
    dispatch(previewFile(selected));
  };

  const handleRU = (data) => {
    dispatch(createReadUnderstood(data.users, selected?._id, data.dueDate, data.groups));
  };

  const handleLink = (data) => {
    let url = `/${data.targetType}${data.targetType === 'process' ? 'es' : 's'}/${data.targetItem}`;
    if (data?.targetType === 'document') {
      url = `/DocLibrary/preview/${data.targetItem}`;
    }
    window.open(url, '_blank');
  };

  const downloadAction = async () => {
    if (selected?.category !== 'qhub') {
      let downloadUrl = await getDecryptedFile(selected);
      downloadFile(downloadUrl, documentName);
    } else {
      dispatch(generatePdf(selected?._id, true));
    }
  };

  const handleDownload = async (selected) => {
    if (conventionSets) {
      if (selected?.conventionSet) {
        const set = sets.find((set) => set._id === selected?.conventionSet);
        const staticFields = set?.fields.filter((field) => ['autonumber', 'text'].includes(field.fieldType));
        if (staticFields?.length === set?.fields?.length) {
          await downloadAction();
        }
        const filteredFields = set?.fields?.filter((field) =>
          ['textArea', 'number', 'dropbox'].includes(field.fieldType),
        );
        if (
          filteredFields?.length === selected?.conventionSetParams?.length &&
          selected?.conventionSetParams.every((x) => x.value !== '')
        ) {
          await downloadAction();
        } else {
          setDownloadTrigger(true);
          setConventionDialog(true);
        }
      } else {
        await downloadAction();
      }
    } else {
      await downloadAction();
    }
  };

  const getIssueChipContent = () => {
    if (selected?.manualIssue) {
      if (revisionFiles?.length > 0) {
        const latestRevision = revisionFiles[revisionFiles.length - 1];
        return `V${latestRevision?.manualIssue} in Progress`;
      }
      return 'In Progress';
    } else {
      return `V${selected.issue + 1} in Progress`;
    }
  };

  const handleConventionClose = () => {
    setConventionDialog(false);
  };

  const handleConventionSet = (e) => {
    dispatch(
      updateDocumentAndDownload(
        selected?._id,
        e,
        () => {
          setConventionDialog(false);
          if (issuing) {
            createIssue();
          }
        },
        downloadTrigger,
      ),
    );
  };

  const onLinkResult = (data) => {
    // createRefLink
    const newItems = data?.new;
    const deleted = data?.deleted;
    let reference = newItems?.map((x) => ({
      ...x,
      sourceDocument: selected?._id,
      sourceSelection: '',
      text: '',
      miniVersion: selected?.issue,
      status: 'published',
    }));
    dispatch(
      addLink({
        new: reference,
        deleted,
      }),
    );
    setRefDialog(false);
  };

  const getLastModified = () => {
    if (!revisionFiles || (isArray(revisionFiles) && revisionFiles?.length === 0)) {
      return moment(selected?.lastModified).format('DD/MM/YY');
    }
    const revision = revisionFiles[revisionFiles?.length - 1];
    return moment(revision?.createdAt).format('DD/MM/YY');
  };

  const checkReviewStatus = () => {
    if (!selected?.reviewSettings) return '';
    const reviewDateStatus = getReviewStatus(selected?.reviewDate);
    return reviewDateStatus;
  };

  return (
    <ClickAwayListener onClickAway={(e) => {}}>
      <Drawer
        open={docDrawer}
        onClose={handleClose}
        anchor={'right'}
        sx={{
          width: {
            xs: '100%',
            md: '600px',
          },
          flexShrink: 0,
          whiteSpace: 'nowrap',
          overFlowY: 'scroll',
          marginTop: '64px',
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: docDrawer
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
            }),
          ...(!docDrawer && { overflowX: 'hidden' }),
        }}
        ModalProps={{ hideBackdrop: false }}
        PaperProps={{
          elevation: 10,
          sx: {
            width: {
              xs: '100%',
              md: '600px',
            },
            borderTopLeftRadius: {
              xs: '0px',
              md: '30px',
            },
            borderBottomLeftRadius: {
              xs: '0px',
              md: '30px',
            },
            transition: (theme) =>
              theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: docDrawer
                  ? theme.transitions.duration.enteringScreen
                  : theme.transitions.duration.leavingScreen,
              }),
            ...(!docDrawer && { overflowX: 'hidden' }),
          },
        }}
      >
        <Grid
          item
          container
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ padding: '10px', marginBottom: '5px' }}
        >
          {selected && (
            <Grid item xs={12} sm={7} container direction="row" alignItems={'center'} sx={{ padding: '10px' }}>
              <DocIcon />
              <Typography
                sx={{ marginRight: '5px', fontSize: (theme) => theme.typography.pxToRem(15), marginLeft: '15px' }}
                noWrap={true}
              >
                {documentName?.length > 20 ? `${documentName?.substring(0, 20)}...` : documentName}
              </Typography>
              <Chip
                size="small"
                sx={{ backgroundColor: COLORS.SEARCH, color: 'white' }}
                label={formatRevision(
                  selected?.preApproval ? '0' : selected.issue,
                  selected.revisionFormat,
                  selected?.manualIssueControl,
                  selected?.manualIssue,
                )}
              />
            </Grid>
          )}
          {selected && (
            <ShareMenu open={!!shareMenu} anchorEl={shareMenu} onClose={() => setShareMenu(null)} document={selected} />
          )}
          {selected && (
            <Grid item xs={12} sm={5} align="right">
              {((ruAccess && ruPageAccess === 'admin') || !ruAccess) && (
                <IconButton variant="outlined" color="default" onClick={() => setOpenRuDialog(true)}>
                  <ReadUnderstoodIcon />
                </IconButton>
              )}
              {!selected?.path?.includes('My Files') &&
                ((user?.access === 'view' && user?._id === selected?.owner_id?._id) || user?.access !== 'view') && (
                  <IconButton variant="outlined" color="default" onClick={() => dispatch(openSetupDialog(true))}>
                    <SettingsIcon />
                  </IconButton>
                )}
              {user?.access !== 'view' &&
                selected &&
                selected.category !== 'qhub' &&
                selected.file &&
                selected.file.location && (
                  <IconButton variant="outlined" color="default" onClick={() => handleDownload(selected)}>
                    <CloudDownloadIcon />
                  </IconButton>
                )}
              {documentSharing && (
                <IconButton variant="outlined" color="default" onClick={(event) => setShareMenu(event.currentTarget)}>
                  <ShareIcon />
                </IconButton>
              )}
              <IconButton onClick={handleClose} sx={{ marginLeft: '5px' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        {!selected && documentLoading && (
          <Grid item container justifyContent="center" alignItems="center" sx={{ p: 5, height: '90vh' }}>
            <CircularProgress size={40} />
          </Grid>
        )}
        {selected && (
          <Grid
            item
            container
            direction={'column'}
            alignItems={'center'}
            sx={{ marginTop: '5px', marginBottom: '10px' }}
          >
            <Panel
              id={'docInfo'}
              title={'Document Information'}
              expanded={expanded === 'docInfo'}
              setExpanded={handleChange}
              icon={<InfoIcon sx={getIconStyles('docInfo')} />}
            >
              <Grid container direction={'row'} spacing={2} justifyContent="space-between">
                <Grid item xs={12} container direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                  {editTitle ? (
                    <InputField
                      fullWidth
                      margin="dense"
                      field={title}
                      label={'File name'}
                      initialValue={title}
                      setField={(e) => setTitle(e)}
                      onSave={(e) => dispatch(updateDocument(selected._id, { title: e }))}
                      onCancel={() => setEditTitle(!editTitle)}
                      edit={true}
                    />
                  ) : (
                    <>
                      <Typography sx={{ marginRight: '5px', maxWidth: '90%' }} noWrap>
                        File name:{' '}
                        <Box component="span" sx={{ fontWeight: 'bold' }}>
                          {documentName}
                        </Box>
                      </Typography>
                      {user?.access !== 'view' && (
                        <IconButton
                          onClick={() => {
                            if (conventionSets && selected?.conventionSet) {
                              setDownloadTrigger(false);
                              setConventionDialog(true);
                            } else {
                              setTitle(selected.title);
                              setEditTitle(!editTitle);
                            }
                          }}
                          size="small"
                        >
                          {editTitle ? <CheckIcon /> : <EditIcon fontSize={'small'} />}
                        </IconButton>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} container direction={'row'} alignItems="center">
                  <Typography sx={{ marginRight: '5px' }}>
                    Uploaded by:{' '}
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                      {selected.owner_id?.fullName}
                    </Box>
                  </Typography>
                  <IconButton
                    onClick={() => {
                      setOwnerDialog(true);
                    }}
                    size="small"
                    disabled={cantChangeOwner}
                  >
                    {cantChangeOwner ? <EditIconOutlined fontSize={'small'} /> : <EditIcon fontSize={'small'} />}
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Upload date:{' '}
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                      {formatDate(selected?.createdAt, 'DD/MM/YYYY')}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Current Version:{' '}
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                      {formatRevision(
                        selected?.preApproval ? '0' : selected.issue,
                        selected?.revisionFormat,
                        selected?.manualIssueControl,
                        selected?.manualIssue,
                      )}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Last Modified:{' '}
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                      {getLastModified()}
                    </Box>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  wrap={'nowrap'}
                >
                  {editTags ? (
                    <>
                      <AutoSelection
                        freeSolo={true}
                        placeholder={'Add a Tag'}
                        label={selected.tags.length > 0 ? 'Tags' : 'No Tags Found'}
                        data={tags}
                        optionTitle={'name'}
                        disabled={false}
                        value={selected.tags}
                        handleChange={(e, value) => dispatch(setSelectedDoc({ tags: value }))}
                        tag
                      />
                    </>
                  ) : (
                    <Typography sx={{ marginRight: '5px' }}>
                      Tags:{' '}
                      <Box component="span" sx={{ fontWeight: 'bold' }}>
                        {selected.tags && selected.tags.length > 0
                          ? selected.tags.map((x) => x.name).toString()
                          : 'No Tags'}
                      </Box>
                    </Typography>
                  )}
                  {user?.access !== 'view' && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (editTags) {
                          dispatch(
                            updateDocument(selected._id, {
                              tags: selected.tags?.length > 0 ? selected.tags.map((x) => x._id) : [],
                            }),
                          );
                          setEditTags(false);
                        } else {
                          setEditTags(!editTags);
                        }
                      }}
                    >
                      {editTags ? <CheckIcon /> : <EditIcon fontSize={'small'} />}
                    </IconButton>
                  )}
                  {editTags && (
                    <IconButton onClick={() => setEditTags(!editTags)} size="small">
                      <CancelIcon />
                    </IconButton>
                  )}
                  {isTagsAvailable() && <TagsDialog />}
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                  wrap={'nowrap'}
                >
                  <Button
                    size="small"
                    sx={{ textTransform: 'none' }}
                    variant="outlined"
                    onClick={() => setReviewerApproverDialog(true)}
                  >
                    Current Reviewers and Approvers
                  </Button>
                  <DocumentReviewersApproversDialog
                    id={selected?._id}
                    area={selected?.area?._id || selected?.area}
                    open={reviewerApproverDialog}
                    handleClose={() => setReviewerApproverDialog(false)}
                  />
                </Grid>
              </Grid>
            </Panel>
            <Panel
              id={'version'}
              title={'Version History'}
              expanded={expanded === 'version'}
              setExpanded={handleChange}
              icon={<DocIcon sx={getIconStyles('version')} />}
              button={
                <Button endIcon={buttonOptions[0].icon} onClick={buttonOptions[0].action} {...buttonOptions[0].props}>
                  {selected.controlled ? buttonOptions[0].name : 'Edit'}
                </Button>
              }
              enableChip={selected.locked}
              chipContent={getIssueChipContent()}
              chipColor={COLORS.SEARCH}
            >
              <Grid container direction={'column'}>
                {revisionFiles && revisionFiles.length > 0 && (
                  <TableContainer elevation={0}>
                    <Table aria-label="simple table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Revision</TableCell>
                          <TableCell align="left">Date</TableCell>
                          <TableCell align="left">Reviewed By</TableCell>
                          {selected.controlled && <TableCell align="left">Reason</TableCell>}
                          <TableCell align="left">File</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {revisionFiles?.map((doc, i) => (
                          <React.Fragment key={i}>
                            <TableRow key={i}>
                              <TableCell align="left">
                                {selected.controlled && (
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => {
                                      setSelectedVersion(selectedVersion === doc._id ? null : doc._id);
                                      dispatch(getMiniRevisions(doc.document._id, doc.baseVersion));
                                    }}
                                  >
                                    {selectedVersion === doc._id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                )}
                                {formatRevision(
                                  doc.baseVersion,
                                  doc.revisionFormat,
                                  doc.manualIssueControl,
                                  doc.baseManualIssue,
                                )}{' '}
                                {doc.upissuing &&
                                  ` > ${formatRevision(
                                    doc.nextVersion,
                                    doc.revisionFormat,
                                    doc.manualIssueControl,
                                    doc.manualIssue || '-',
                                  )} (Pending)`}
                              </TableCell>
                              <TableCell align="left">{moment(doc.createdAt).format('DD/MM/YY')}</TableCell>
                              <TableCell align="left">
                                <User id={doc.issuedBy} clickable avatarSize={20} />
                              </TableCell>
                              <TableCell align="left">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    if (doc.upissuing) {
                                      dispatch(setIssueDoc(doc, Boolean(doc.category === 'normal')));
                                      history.push(`/editor/${doc.document._id}/upissue/${doc._id}`);
                                      handleClose();
                                    } else {
                                      if (doc.controlled) {
                                        setRevision(doc);
                                        setRevisionInfoDialog(true);
                                      } else {
                                        openFile(doc);
                                      }
                                    }
                                  }}
                                >
                                  {doc.upissuing ? <EditIcon /> : doc.controlled ? <VisibilityIcon /> : <DocIcon />}
                                </IconButton>
                              </TableCell>
                              {doc.controlled && (
                                <TableCell align="left">
                                  <IconButton size="small" onClick={() => openFile(doc)}>
                                    <DocIcon />
                                  </IconButton>
                                </TableCell>
                              )}
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={selectedVersion === doc._id} timeout="auto" unmountOnExit>
                                  <Box sx={{ margin: 1 }}>
                                    <Typography variant="caption" gutterBottom component="div">
                                      {miniRevisions && miniRevisions.length > 0 ? 'Updates' : 'No Updates Available'}
                                    </Typography>
                                    {miniRevisions && miniRevisions.length > 0 && (
                                      <Table size="small" aria-label="minor-updates">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell align="left">Revision</TableCell>
                                            <TableCell align="left">Date</TableCell>
                                            <TableCell align="left">Reviewed By</TableCell>
                                            <TableCell align="left">Info</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {miniRevisions?.map((minorDoc) => (
                                            <TableRow key={minorDoc._id}>
                                              <TableCell align="left">
                                                {minorDoc.manualIssueControl
                                                  ? minorDoc.manualIssue || '-'
                                                  : minorDoc.baseVersion}
                                                .{minorDoc.version}
                                              </TableCell>
                                              <TableCell align="left">
                                                {moment(minorDoc.createdAt).format('DD/MM/YY')}
                                              </TableCell>
                                              <TableCell align="left">
                                                <User id={minorDoc.issuedBy} clickable avatarSize={20} />
                                              </TableCell>
                                              <TableCell align="left">
                                                <IconButton
                                                  size="small"
                                                  onClick={() => {
                                                    setIssueDetails(minorDoc);
                                                    setIssue(true);
                                                  }}
                                                >
                                                  <VisibilityIcon />
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    )}
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </Panel>
            {selected.controlled && (
              <Panel
                id={'reviews'}
                title={'Reviews'}
                expanded={expanded === 'reviews'}
                setExpanded={handleChange}
                icon={<LibraryBooksIcon sx={getIconStyles('reviews')} />}
                button={
                  <Button endIcon={buttonOptions[1].icon} onClick={buttonOptions[1].action} {...buttonOptions[1].props}>
                    {buttonOptions[1].name}
                  </Button>
                }
                enableChip={selected.reviewSettings && selected.reviewDate}
                chipColor={
                  inProgressReview
                    ? 'green'
                    : isReviewOverdue(selected.reviewDate)
                    ? 'red'
                    : getReviewStatus(selected.reviewDate).includes('Next Review')
                    ? 'green'
                    : 'orange'
                }
                chipContent={inProgressReview ? 'Under Review' : checkReviewStatus()}
              >
                <Grid container direction={'column'}>
                  {reviews && reviews.length === 0 && <Typography variant="caption">No Reviews yet</Typography>}
                  {reviews && reviews.length > 0 && (
                    <TableContainer>
                      <Table aria-label="reviews table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Date of Review</TableCell>
                            <TableCell align="left">Version</TableCell>
                            <TableCell align="left">Assigned</TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {reviews.map((doc, i) => (
                            <TableRow key={i}>
                              <TableCell align="left">
                                {moment(doc.completedAt || doc.createdAt).format('DD/MM/YY')}
                              </TableCell>
                              <TableCell align="left">V{reviews?.length - i}</TableCell>
                              <TableCell align="left">
                                <UserGroup ids={doc.assigned} clickable avatarSize={25} />
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  size="small"
                                  onClick={() => StateManager.openReviewDialog(doc?._id)}
                                  startIcon={doc.completed ? <VisibilityIcon /> : <EditIcon />}
                                >
                                  {doc.completed ? 'View' : 'Edit'}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  {selected.reviewSettings && <Divider inset="true" sx={{ marginBottom: '15px' }} />}
                  {selected.reviewSettings && (
                    <Grid item container direction="row" alignItems="center" spacing={4}>
                      {selected.reviewDate && (
                        <Grid item sm={4} sx={{ marginRight: 1 }}>
                          <Typography
                            sx={{
                              fontWeight: isReviewOverdue(selected.reviewDate) ? 'bold' : 'normal',
                              color: (t) => (isReviewOverdue(selected.reviewDate) ? 'red' : t.palette.text.primary),
                            }}
                          >
                            {checkReviewStatus()}
                          </Typography>
                        </Grid>
                      )}
                      {!editDate && (
                        <Grid item sm={3}>
                          <Button size="small" onClick={onReviewDateEdit} startIcon={<EditIcon />}>
                            Edit Review Date
                          </Button>
                        </Grid>
                      )}
                      {editDate && (
                        <Grid item container alignItems="flex-end" sx={{ columnGap: 2 }}>
                          <Grid item sm={4}>
                            <DatePicker value={reviewDate} onChange={setReviewDate} />
                          </Grid>
                          {editDate && (
                            <IconButton
                              size="small"
                              onClick={() => {
                                dispatch(updateDocument(selected._id, { reviewDate }));
                                setEditDate(false);
                              }}
                            >
                              <DoneIcon />
                            </IconButton>
                          )}
                          {editDate && (
                            <IconButton size="small" onClick={() => setEditDate(false)}>
                              <CancelIcon />
                            </IconButton>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Panel>
            )}
            {selected.controlled && (
              <Panel
                id={'references'}
                title={'Links'}
                expanded={expanded === 'references'}
                setExpanded={handleChange}
                icon={<LinkIcon sx={getIconStyles('references')} />}
                button={
                  (user?.access === 'admin' || selected?.owner_id?._id === user?._id) && (
                    <Button
                      color="primary"
                      onClick={() => setRefDialog(true)}
                      endIcon={<LinkIcon sx={getIconStyles('references')} />}
                      {...buttonOptions[2].props}
                    >
                      Add Links
                    </Button>
                  )
                }
              >
                <Grid container direction={'column'}>
                  {linkedFiles?.length > 0 && (
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left" sx={{ color: 'primary.main', fontWeight: 600 }} width="50%">
                              Link
                            </TableCell>
                            <TableCell align="center" sx={{ color: 'primary.main', fontWeight: 600 }}>
                              Options
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {linkedFiles?.map((doc, i) => (
                            <TableRow key={i} component={Paper} elevation={2}>
                              <TableCell align="left" width="70%">
                                {doc?.targetType === 'document' && <Document id={doc?.targetItem} avatarSize={16} />}
                                {doc?.targetType !== 'document' && (
                                  <TooltipTypography sx={{ width: '150px' }} align="left">
                                    {doc?.title}
                                  </TooltipTypography>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <TooltipIconButton text="Open Link" onClick={() => handleLink(doc)}>
                                  <LinkIcon sx={getIconStyles('references')} />
                                </TooltipIconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              </Panel>
            )}
            <Panel
              id={'comments'}
              title={'Comments'}
              expanded={expanded === 'comments'}
              setExpanded={handleChange}
              icon={<ChatIcon sx={getIconStyles('comments')} />}
            >
              <Grid container direction={'column'} sx={{ overFlowY: 'scroll' }}>
                {comments && (
                  <CommentsSection
                    loading={fetching}
                    comments={comments}
                    showCommentBar={false}
                    upIssueComment={false}
                    bottomButton={true}
                    addComment={(data) => {
                      const commentData = {
                        text: data.text,
                        mentionData: {
                          list: data.mentionList,
                          link: `/DocLibrary/preview/${selected?._id}`,
                          type: 'Document',
                          title: selected?.title,
                        },
                      };
                      dispatch(addComment(selected._id, commentData));
                    }}
                    updateComment={(d) => dispatch(updateComment(selected._id, d.id, d.data))}
                    deleteComment={(id) => dispatch(deleteComment(selected._id, id))}
                    likeComment={(d) =>
                      dispatch(likeComment(selected._id, d.id, { ...d.data, document: selected._id }))
                    }
                    versions={
                      revisionFiles && revisionFiles.length > 0
                        ? revisionFiles.map((x, index) => ({
                            label: `Version ${index + 1}`,
                            value: index + 1,
                          }))
                        : []
                    }
                  />
                )}
              </Grid>
            </Panel>
            {issue && issueDetails && (
              <IssueInfo
                open={issue}
                onClose={() => {
                  setIssueDetails(null);
                  setIssue(false);
                }}
                issueId={issueDetails._id}
                document={issueDetails}
                baseIssue={selectedVersion}
                miniVersion={issueDetails.version}
              />
            )}
            {revision && (
              <RevisionInfoDialog
                open={revisionInfoDialog}
                handleClose={setRevisionInfoDialog}
                document={revision}
                setIssueDetails={(doc, issueId) => {
                  setIssueDetails(doc);
                  setSelectedVersion(issueId);
                  setIssue(true);
                }}
              />
            )}
            <RefLinkDialog
              open={refDialog}
              handleClose={() => setRefDialog(false)}
              handleCreate={onLinkResult}
              initial={linkedFiles}
            />
          </Grid>
        )}
        <SendRUTaskDialog
          open={openRuDIalog}
          handleClose={() => setOpenRuDialog(false)}
          handleConfirm={handleRU}
          title={selected?.title}
        />
        <UseConventionSetDialog
          open={conventionDialog}
          handleClose={handleConventionClose}
          title={title}
          setId={selected?.conventionSet}
          initial={selected?.conventionSetParams}
          sequence={selected?.sequence}
          handleConfirm={handleConventionSet}
          saving={updatingDocument}
        />
        <ChangeOwnerDialog
          open={ownerDialog}
          handleClose={() => setOwnerDialog(false)}
          initiallySelected={selected?.owner_id?._id?.toString()}
          handleConfirm={handleOwnerChange}
          confirmButtonSuffix="Owner"
          changing={ownerUpdating}
        />
      </Drawer>
    </ClickAwayListener>
  );
}
