import { Grid, Typography, Paper, Chip, Button, Tooltip, IconButton, Collapse } from '@mui/material';
import { DialogContent, DialogTitle, Badge, useTheme, useMediaQuery, Fab } from '@mui/material';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { CheckCircleOutlineRounded, DeleteOutlineRounded, InfoOutlined, EditRounded } from '@mui/icons-material';
import { GetAppRounded, MailOutlineRounded, SettingsOverscanRounded } from '@mui/icons-material';
import { AlarmOnRounded, AlarmOffRounded, AlarmAddRounded, ChevronLeftRounded } from '@mui/icons-material';
import { ChevronRightRounded, NotificationsActiveRounded, CheckCircleRounded } from '@mui/icons-material';
import { TimelineRounded, DescriptionRounded, HighlightOffRounded, HistoryRounded } from '@mui/icons-material';
import { LibraryBooksRounded, UnarchiveRounded } from '@mui/icons-material';
import { CircularProgress, LinearProgress, FormControlLabel, Switch } from '@mui/material';
import StateManager from '../../../Global/StateManager';
import { useParams, useHistory } from 'react-router-dom';
import { Field } from '../../../Global/Fields';
import { ProcessIcon, FormIcon } from '../../../Global/Icons';
import { useQuery } from '../../../Global/hooks';
import { UserGroup, User, Approval, PageContainer, DatePicker, TimePicker, NcrInfo } from '../../../Global/Components';
import { HtmlContent, PicturesCarousel, BackArrowButton, FromNow, CancelDialog } from '../../../Global/Components';
import { RoundedDialog, StandardDialogActions, TitleTypography, TextDialog } from '../../../Global/Components';
import { ReviewComponent, DateFormatter, AssetReference, PortalSpaceReference } from '../../../Global/Components';
import { FormatDate, FormatDateFull, highlightElement, FormatOnlyDate } from '../../../Global/Functions';
import { grey, deepPurple, teal, red, deepOrange, green } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import EntryInfoDialog from '../components/EntryInfoDialog';
import { BASE_URL } from '../../../../constants';
import GridSection from '../components/GridSection';
import EmailDialog from '../components/EmailDialog';
import FormActionsDialog from '../components/FormActionsDialog';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import moment from 'moment';
import _, { isArray, isNumber, isEmpty, isFunction } from 'lodash';
import { styled } from '@mui/material/styles';
import FieldReference from '../../../Global/Fields/FieldReference';
import ExecutedActionReference from '../../../Global/Fields/ExecutedActionReference';
import PortalSpacesSection from '../components/PortalSpacesSection';
import DeleteEntryDialog from '../components/DeleteEntryDialog';
import FieldDataGrid from '../../../Global/Fields/FieldDataGrid';
import AttachmentsBar from '../components/AttachmentsBar';
import ActionsBar from '../components/ActionsBar';
import EntryVersionsDialog from '../components/EntryVersionsDialog';
import ReviewDialog from '../../../Global/Components/ReviewDialog';

const ReferenceItem = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.spacing(1.5),
}));

const SectionContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 0.5),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 1),
  },
}));

const SectionPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2),
  },
  borderRadius: theme.spacing(2),
}));

function TabPanel({ children, value, index, ...props }) {
  return (
    <Grid
      container
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...props}
    >
      {children}
    </Grid>
  );
}

function numberToColumn(n) {
  const res = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[n % 26];
  return n >= 26 ? numberToColumn(Math.floor(n / 26) - 1) + res : res;
}

export function formatEntryVersion(versionFormat, entryVersion) {
  if (!isNumber(entryVersion)) return;

  return numberToColumn(entryVersion - 1);

  /*if (!versionFormat || versionFormat === 'v') return `v${entryVersion}`;
  if (versionFormat === '01') return `${entryVersion < 10 ? 0 : ''}${entryVersion}`;
  if (versionFormat === '1') return `${entryVersion}`;
  if (versionFormat === 'A') return numberToColumn(entryVersion - 1);*/
}

export default function FormEntry({ dialogEntryId, fromDialog, onDialogClose, onFormComplete, onFormDelete }) {
  const { stepId, itemId, portalSpaceId } = useQuery();
  const history = useHistory();
  const { entryId: urlEntryId } = useParams();
  const entryId = fromDialog ? dialogEntryId : urlEntryId;
  const { user } = useSelector(({ profile }) => profile);
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const iconColor = theme.palette.text.secondary;
  const BACKUP_INTERVAL = 10 * 1000;
  const backupTimer = useRef();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('Oops :-(');
  const [submiting, setSubmiting] = useState(false);
  const [entry, setEntry] = useState(null);
  const [entryInfoDialog, setEntryInfoDialog] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const [startingEditing, setStartingEditing] = useState(false);
  const [upissuing, setUpissuing] = useState(false);
  const [versionsDialog, setVersionsDialog] = useState(false);
  const [upIssueDialog, setUpIssueDialog] = useState(false);
  const [reminderDialog, setReminderDialog] = useState(false);
  const [currSectionIndex, setCurrSectionIndex] = useState(0);
  const [checkingSection, setCheckingSection] = useState(null);
  const [actionsDialog, setActionsDialog] = useState(false);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [hiddenSections, setHiddenSections] = useState([]);
  const [allCreatedActions, setAllCreatedActions] = useState([]);
  const [portalSpacesSection, setPortalSpacesSection] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [executedActions, setExecutedActions] = useState([]);
  const [reviewDialog, setReviewDialog] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [cancellingEdit, setCancellingEdit] = useState(false);
  const [unarchiving, setUnarchiving] = useState(false);
  const executedActionsRef = useRef([]);
  const shownSection = _.isArray(entry?.sections)
    ? entry.sections.filter(({ id }) => !hiddenSections.includes(id))
    : [];
  const lastSection = _.isArray(entry?.sections) && shownSection.length === currSectionIndex + 1;

  const upissuingByUser = user && upissuing && entry?.editingUsers?.includes(user?._id);
  const fillingByUser = user && !upissuing && entry?.assignedUsers.includes(user?._id);
  const userAdmin = user?.access === 'admin';

  const editable =
    entry &&
    user &&
    !entry.completedAt &&
    !entry.submittedForApprovalAt &&
    (fillingByUser || upissuingByUser || userAdmin);

  const progress = ((currSectionIndex + 1) / (shownSection.length || 1)) * 100;
  const hasCompleteActions = _.isArray(entry?.completeActions) && !_.isEmpty(entry.completeActions);

  useEffect(() => {
    if (!entryId) return;
    setLoading(true);
    axios
      .get('/forms/entries/getEntry', { params: { entryId } })
      .then(({ data }) => {
        setEntry(data);

        setHiddenItems(data.hiddenItems);
        setHiddenSections(data.hiddenSections);
        setAllCreatedActions(data.allCreatedActions);
        setPortalSpacesSection(data.portalSpaceLinksOpen);
        setReviews(isArray(data.reviews) ? data.reviews : []);
        if (data.executedActions) {
          setExecutedActions(data.executedActions);
          executedActionsRef.current = data.executedActions;
        }
        if (itemId) {
          setTimeout(() => {
            highlightElement(itemId);
          }, 400); // to let it render
        }
        if (!data.completedAt && data.approvalId) {
          const totalSections = data.sections.filter(({ id }) => !data.hiddenSections.includes(id)).length;
          setCurrSectionIndex(totalSections - 1);
        }

        setUpissuing(['editing', 'upissuing'].includes(data.state));
        setLoading(false);

        StateManager.subscribeToExecutedActions(onExecutedActionChange);
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Sometning went wrong');
        setLoading(false);
      });
  }, [entryId]); // eslint-disable-line

  function goBack() {
    if (onDialogClose && fromDialog) {
      onDialogClose();
    } else {
      history.go(-1);
    }
  }

  function onExecutedActionChange(event) {
    if (event.type === 'newAction' && event.notification?.source?.formEntryId === entryId && event.notification?.item) {
      executedActionsRef.current.push(event.notification.item);
      setExecutedActions([...executedActionsRef.current]);
    }

    if (event.type === 'undoneAction') {
      executedActionsRef.current = executedActionsRef.current.filter(
        (x) => x.executedActionId !== event.executedActionId,
      );
      setExecutedActions([...executedActionsRef.current]);
    }
  }

  function deleteActionById(id) {
    executedActionsRef.current = executedActionsRef.current.filter((x) => x._id !== id);
    setExecutedActions([...executedActionsRef.current]);

    onDeletedAction(id);
  }

  function checkToSubmit() {
    if (submiting) return;

    if (entry.approvalOptions && !entry.approved) {
      selectApprovalUsers();
      return;
    }
    if (hasCompleteActions) {
      setActionsDialog(true);
      return;
    }
    submitForm();
  }

  function submitForm(params) {
    setSubmiting(true);
    axios
      .post('/forms/entries/submitForm', { entryId: entry._id, ...params })
      .then((res) => {
        setSubmiting(false);
        if (res.data.passed === false) {
          StateManager.setErrorAlert(res.data.message);
          highlightElement(res.data.fieldId);
          return;
        }
        StateManager.setSuccessAlert('Form has been submitted');
        const result = {
          ...entry,
          completedAt: res.data.completedAt,
          completedBy: res.data.completedBy,
        };

        setEntry(result);

        localStorage.removeItem(`form-entry-${entry._id}`);

        if (isFunction(onFormComplete)) {
          onFormComplete(result);
        }

        if (stepId) {
          history.push(`/processes/step/${stepId}`);
        } else {
          goBack();
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSubmiting(false);
      });
  }

  function checkEntrySection(direction) {
    const currentSection = _.isArray(entry?.sections) ? shownSection[currSectionIndex] : null;
    if (!currentSection?.id) return;

    setCheckingSection(direction);
    axios
      .post('/forms/entries/checkEntrySection', { entryId: entry._id, sectionId: currentSection?.id })
      .then((res) => {
        setCheckingSection(null);
        if (res.data.passed === true) {
          setCurrSectionIndex(currSectionIndex + 1);
        } else {
          StateManager.setErrorAlert(res.data.message);
          highlightElement(res.data.fieldId);
        }
      })
      .catch((err) => {
        setCheckingSection(null);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function selectApprovalUsers(upIssueReason) {
    if (
      entry.approvalOptions?.approvalUsers?.length === 1 ||
      entry.approvalId ||
      entry.settings?.sendApprovalToAllUsers
    ) {
      submitForApproval({ users: entry.approvalOptions?.approvalUsers, upIssueReason });
      return;
    }
    StateManager.selectMultipleUsers((res) => submitForApproval({ ...res, upIssueReason }), {
      includedUsers: entry.approvalOptions?.approvalUsers,
      initiallySelected: [],
      title: 'Select users to approve the entry',
    });
  }

  function submitForApproval(result) {
    if (!result.users) return;
    setEntry({ ...entry, approvalId: null });
    setSubmiting(true);
    axios
      .post('/forms/entries/submitFormForApproval', {
        entryId: entry._id,
        approvalUsers: result.users,
        upIssueReason: result.upIssueReason,
      })
      .then((res) => {
        setSubmiting(false);
        if (res.data.passed === false) {
          StateManager.setErrorAlert(res.data.message);
          highlightElement(res.data.fieldId);
          return;
        }
        StateManager.setSuccessAlert('Form has been submitted for approval');
        setEntry({
          ...entry,
          submittedForApprovalAt: res.data.submittedForApprovalAt,
          submittedForApprovalBy: res.data.submittedForApprovalBy,
          approvalId: res.data.approvalId,
          editable: false,
        });
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSubmiting(false);
      });
  }

  function cancelEntry({ reason }) {
    axios
      .post('/forms/entries/cancelEntry', { entryId: entry._id, reason })
      .then((res) => {
        StateManager.setSuccessAlert('Entry has been cancelled');
        goBack();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function addZeros(number) {
    return number < 100 ? (number < 10 ? `00${number}` : `0${number}`) : number;
  }

  function startEdit() {
    setStartingEditing(true);

    const body = { entryId: entry._id };
    axios
      .post('/forms/entries/startEdit', body)
      .then(({ data }) => {
        setStartingEditing(false);

        onUpIssue(data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setStartingEditing(false);
      });
  }

  function onUpIssue(params) {
    setUpissuing(true);
    const res = {
      ...entry,
      completedAt: null,
      approvalId: params.approvalArchived ? null : entry.approvalId,
      submittedForApprovalAt: null,
      approved: false,
      editingStartedBy: params.editingStartedBy,
      editingUsers: params.editingUsers,
      state: params.state,
    };
    setEntry(res);
  }

  function checkUpIssue() {
    if (entry.entriesControlled && !entry.upIssueReason && entry.state === 'upissuing') {
      setUpIssueDialog(true);
    } else {
      finishEdit();
    }
  }

  function finishEdit(reason) {
    if (!upissuing) return;

    if (entry.approvalOptions && !entry.approved && entry.state === 'upissuing') {
      selectApprovalUsers(reason);
      return;
    }

    setSubmiting(true);
    const body = { entryId: entry._id, reason };
    axios
      .post('/forms/entries/finishEdit', body)
      .then(({ data }) => {
        setSubmiting(false);
        if (data.passed === false) {
          StateManager.setErrorAlert(data.message);
          highlightElement(data.fieldId);
          return;
        }
        setEntry({ ...entry, ...data });
        StateManager.setSuccessAlert('Form has been saved');
        setUpissuing(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSubmiting(false);
      });
  }

  function downloadPDF() {
    if (downloading) return;
    setDownloading(true);
    axios
      .get('/forms/entries/formToPdf', { params: { entryId: entry._id } })
      .then((res) => {
        window.open(`${BASE_URL}${res.data.link}`, '_blank');
        setDownloading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setDownloading(false);
      });
  }

  function onCalculationUpdate(calculated) {
    let modified = false;

    for (let i = 0; i < calculated.length; ++i) {
      for (let j = 0; j < entry.sections.length; ++j) {
        const itemIndex = entry.sections[j].items.findIndex(({ id }) => calculated[i].fieldId === id);
        if (itemIndex === -1) continue;
        entry.sections[j].items[itemIndex].filledValue = calculated[i].value;
        modified = true;
      }
    }

    if (modified) {
      setEntry(_.cloneDeep(entry));
    }
  }

  function onHiddenUpdate({ hiddenItems, hiddenSections }) {
    if (_.isArray(hiddenItems)) {
      setHiddenItems(hiddenItems);
    }
    if (_.isArray(hiddenSections)) {
      setHiddenSections(hiddenSections);
    }
  }

  function onCreatedAction(action) {
    setAllCreatedActions([...allCreatedActions, action]);
  }

  function onDeletedAction(actionId) {
    setAllCreatedActions(allCreatedActions.filter((x) => x._id !== actionId));
  }

  function resetTimer() {
    if (backupTimer.current != null) {
      clearTimeout(backupTimer.current);
    }
    backupTimer.current = setTimeout(backupEntry, BACKUP_INTERVAL);
  }

  function backupEntry() {
    const saved = localStorage.getItem(`form-entry-${entry._id}`);
    if (!saved) return;
    const parsed = JSON.parse(saved);
    if (_.isEmpty(parsed)) return;
    const body = { entryId: entry._id, data: parsed };
    axios.post('/forms/entries/backupEntry', body).catch((err) => {
      console.error(err);
    });
  }

  function deleteSignatureColumnsFromRows(columns, rows) {
    const rowsCopy = _.cloneDeep(rows); // without it it affects the actual entry rows
    const signatureColumnIds = columns.filter((x) => x.fieldType === 'signature').map((x) => x.id);
    if (_.isEmpty(signatureColumnIds)) return rowsCopy;

    for (let i = 0; i < rowsCopy.length; ++i) {
      for (let j = 0; j < signatureColumnIds.length; ++j) {
        rowsCopy[i][signatureColumnIds[j]] = null;
      }
    }
    return rowsCopy;
  }

  function cancelEdit() {
    setCancellingEdit(true);

    axios
      .post('/forms/entries/cancelEdit', { entryId: entry._id })
      .then(({ data }) => {
        setEntry(data);
        setHiddenItems(data.hiddenItems);
        setHiddenSections(data.hiddenSections);
        setCancellingEdit(false);
        StateManager.setSuccessAlert('Done');
        setUpissuing(false);
      })
      .catch((err) => {
        setCancellingEdit(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function onSectionUpdate(update) {
    if (!_.isArray(entry?.sections)) return;

    const entryCopy = _.cloneDeep(entry);

    const sectionIndex = entryCopy.sections.findIndex((x) => x.id === update.sectionId);
    if (sectionIndex === -1) return;
    if (update.fieldId) {
      if (!isArray(entryCopy.sections[sectionIndex].items)) return;
      const fieldIndex = entryCopy.sections[sectionIndex].items.findIndex((x) => x.id === update.fieldId);
      if (fieldIndex === -1) return;
      entryCopy.sections[sectionIndex].items[fieldIndex].filledValue = update.value;
    } else if (isArray(update.rows) && entryCopy.sections[sectionIndex].table) {
      entryCopy.sections[sectionIndex].table.rows = update.rows;
    }

    // we don't include signature field, since it's a heavy one
    const toSave = entryCopy.sections
      .map((section) => ({
        id: section.id,
        items: isArray(section.items)
          ? section.items
              .filter((item) => item.fieldType !== 'signature' && item.filledValue != null)
              .map((item) => ({ fieldId: item.id, filledValue: item.filledValue }))
          : [],
        tableRows:
          _.isArray(section.table?.rows) && _.isArray(section.table?.columns)
            ? deleteSignatureColumnsFromRows(section.table.columns, section.table.rows)
            : [],
      }))
      .filter((section) => !_.isEmpty(section.tableRows) || !_.isEmpty(section.items));

    toSave.flatMap((x) => x.items.map((field) => ({ fieldId: field.fieldId, value: field.filledValue })));

    localStorage.setItem(`form-entry-${entry._id}`, JSON.stringify(toSave));
    resetTimer();
  }

  function unarchive() {
    setUnarchiving(true);

    axios
      .post('/forms/archive/unarchiveEntries', { entriesIds: [entry._id] })
      .then(({ data }) => {
        if (data.unarchived?.length > 0) {
          setEntry({ ...entry, archivedAt: null });
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setUnarchiving(false);
      });
  }

  if (loading || !user) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: fromDialog ? '85vh' : '50vh' }}>
        <CircularProgress color="primary" />
      </Grid>
    );
  }

  if (!entry) {
    return (
      <PageContainer>
        <Grid container item style={{ marginTop: 16 }} alignItems="center">
          <BackArrowButton onClick={goBack} />
          <Typography variant="h4">{error}</Typography>
        </Grid>
      </PageContainer>
    );
  }

  const containerPrors = fromDialog ? { lg: 11, md: 11, xs: 12 } : { lg: 8, md: 10, xs: 12 };

  const approvalNeeded = entry.approvalOptions && !entry.approved;

  const ApprovalSection = (
    <>
      {entry.upIssueReason && entry.submittedForApprovalAt && (
        <Grid container component={Paper} item sx={{ my: 2, p: 2 }} direction={'column'}>
          <Grid container item>
            <Typography variant="h6">Up-issue info</Typography>
          </Grid>

          <Grid container item sx={{ mt: 1 }}>
            <Typography sx={{ fontStyle: 'italic' }}>Reason for up-issue:</Typography>
          </Grid>

          <Grid container item sx={{ mt: 1 }}>
            <Typography style={{ whiteSpace: 'break-spaces' }}>{entry.upIssueReason}</Typography>
          </Grid>

          <Grid container item sx={{ mt: 1 }} alignItems="center">
            <Typography>
              Up-issue started{' '}
              {entry.editingStartedAt && <DateFormatter date={entry.editingStartedAt} formatFunction={FormatDate} />} by
            </Typography>

            <User id={entry.editingStartedBy} avatarSize={32} sx={{ ml: 2 }} />
          </Grid>
          <Grid container item sx={{ mt: 1 }} alignItems="center">
            <Typography>
              Submitted for approval
              <DateFormatter date={entry.submittedForApprovalAt} formatFunction={FormatDate} /> by
            </Typography>
            <User
              onlyAvatar={!largeDevices}
              style={{ marginLeft: '1rem' }}
              id={entry.submittedForApprovalBy}
              avatarSize={32}
            />
          </Grid>
        </Grid>
      )}
      {entry.approvalId && <Approval id={entry.approvalId} elevation={3} />}
    </>
  );

  const Reviews =
    !_.isEmpty(reviews) && !upissuing && entry.completedAt ? (
      <Grid container item component={Paper} elevation={3} sx={{ my: 1, py: 1.5, px: 2, borderRadius: 1.5 }}>
        <Grid container item>
          <Typography variant="h6" gutterBottom>
            Reviews:
          </Typography>
        </Grid>
        {reviews.map((review) => (
          <ReviewComponent
            key={review._id}
            initial={review}
            onDelete={() => setReviews(reviews.filter((x) => x._id !== review._id))}
            withNewVersionButton={entry.entriesControlled}
            onUpIssue={onUpIssue}
          />
        ))}
      </Grid>
    ) : null;

  const Attachments = (
    <AttachmentsBar
      initial={entry.files}
      onChange={(files) => setEntry({ ...entry, files })}
      entryId={entry._id}
      editable={editable}
    />
  );

  const Actions = (
    <ActionsBar
      actions={allCreatedActions}
      executedActions={executedActions}
      type="form"
      entryId={entry._id}
      editable={editable}
      onDelete={deleteActionById}
    />
  );

  const ActionsOnComplete =
    !_.isEmpty(entry?.actionsOnComplete) && _.isArray(entry.actionsOnComplete)
      ? entry.actionsOnComplete.map((action) => (
          <ActionButton key={action.id} action={action} parentFormEntryId={entry._id} />
        ))
      : null;

  const StartInfo = (
    <Grid container sx={{ px: 1 }} direction="column">
      {entry.description && (
        <Grid container item sx={{ py: 1 }}>
          <Typography style={{ whiteSpace: 'break-spaces' }}>{entry.description}</Typography>
        </Grid>
      )}
      {entry.ncrId && (
        <NcrInfo
          ncrId={entry.ncrId}
          component={Paper}
          elevation={3}
          sx={{
            p: 2,
            borderRadius: 1.5,
            mb: 2,
          }}
        />
      )}

      {entry.assetId && (
        <AssetReference
          assetId={entry.assetId}
          component={Paper}
          elevation={3}
          sx={{
            p: 2,
            borderRadius: 1.5,
            mb: 2,
          }}
        />
      )}

      {entry.portalSpaceId && (
        <PortalSpaceReference
          portalSpaceId={entry.portalSpaceId}
          component={Paper}
          elevation={3}
          sx={{
            p: 2,
            borderRadius: 1.5,
            mb: 2,
          }}
        />
      )}

      {entry.linkEntriesToPortal && (
        <PortalSpacesSection
          open={portalSpacesSection}
          onClose={() => setPortalSpacesSection(false)}
          onOpen={() => setPortalSpacesSection(true)}
          initial={entry.portalSpaceLinks}
          entryId={entry._id}
          portalList={entry.portalList}
          required={entry.portalSelectionMandatory}
        />
      )}
      {!_.isEmpty(entry.actionReferenceInfo) && (
        <Grid
          container
          component={Paper}
          elevation={3}
          alignItems="center"
          sx={{
            my: 1,
            px: 1.5,
            borderRadius: 1.5,
          }}
        >
          <FieldReference actionReferenceInfo={entry.actionReferenceInfo} actionReferences={entry.actionReferences} />
        </Grid>
      )}
      {entry.executedActionId && (
        <Grid
          container
          component={Paper}
          elevation={3}
          alignItems="center"
          sx={{
            my: 1,
            px: 1.5,
            borderRadius: 1.5,
          }}
        >
          <ExecutedActionReference executedActionId={entry.executedActionId} />
        </Grid>
      )}
      {!_.isEmpty(entry.parentProcess) && (
        <ReferenceItem container component={Paper} elevation={3}>
          <Grid container item wrap="nowrap" alignItems="center" justifyContent="center">
            <Typography style={{ fontWeight: 500 }}>This form was started because of</Typography>
            <Button
              onClick={() => history.push(`/processes/ongoing/${entry.parentProcess._id}`)}
              endIcon={<ProcessIcon />}
              style={{ margin: '0 8px', borderRadius: 8, textTransform: 'none', fontSize: '1rem' }}
            >
              {entry.parentProcess.title} #{entry.parentProcess.number}
            </Button>
          </Grid>
          <Grid container item wrap="nowrap" alignItems="center" justifyContent="center">
            <Typography>The last user{entry.parentProcess.owners?.length === 1 ? '' : 's'} involved was</Typography>
            <UserGroup avatarSize={32} ids={entry.parentProcess.owners} style={{ marginLeft: 8 }} />
          </Grid>
        </ReferenceItem>
      )}
      {!_.isEmpty(entry?.parentFormEntry) && (
        <ReferenceItem container component={Paper} elevation={3} alignItems="center">
          <Typography style={{ fontWeight: 500 }}>This form was started because of</Typography>
          <Button
            onClick={() => history.push(`/forms/entry/${entry.parentFormEntry._id}`)}
            endIcon={<FormIcon />}
            style={{ margin: '0 8px', borderRadius: 8, textTransform: 'none', fontSize: '1rem' }}
          >
            {entry.parentFormEntry.title} #{entry.parentFormEntry.number}
          </Button>
        </ReferenceItem>
      )}
      {!_.isEmpty(entry.processEntries) &&
        _.isArray(entry.processEntries) &&
        entry.processEntries.map((process) => (
          <ReferenceItem key={process._id} container component={Paper} elevation={3} alignItems="center">
            <Button
              onClick={() => history.push(`/processes/ongoing/${process._id}`)}
              endIcon={<ProcessIcon />}
              style={{ marginRight: 8, borderRadius: 8, textTransform: 'none', fontSize: '1rem' }}
            >
              {process.title} #{process.number}
            </Button>
            <Typography>was started because of this entry</Typography>
          </ReferenceItem>
        ))}
      {!_.isEmpty(entry.formEntries) &&
        _.isArray(entry.formEntries) &&
        entry.formEntries.map((formEntry) => (
          <ReferenceItem key={formEntry._id} container component={Paper} elevation={3} alignItems="center">
            <Button
              onClick={() => history.push(`/forms/entry/${formEntry._id}`)}
              endIcon={<FormIcon />}
              style={{ marginRight: 8, borderRadius: 8, textTransform: 'none', fontSize: '1rem' }}
            >
              {formEntry.title} #{formEntry.number}
            </Button>
            <Typography>was started because of this entry</Typography>
          </ReferenceItem>
        ))}
      {!_.isEmpty(entry?.actionsOnStart) &&
        _.isArray(entry.actionsOnStart) &&
        entry.actionsOnStart.map((action) => (
          <ActionButton key={action.id} action={action} parentFormEntryId={entry._id} />
        ))}
    </Grid>
  );

  const formOverdue = !entry.completedAt && entry.dueAt && moment(entry.dueAt) < moment();

  return (
    <PageContainer
      direction="column"
      sx={fromDialog ? { p: 0, position: 'relative', maxHeight: '85vh' } : { height: '100%' }}
    >
      <Grid
        container
        item
        component={fromDialog ? Paper : undefined}
        elevation={fromDialog ? 2 : undefined}
        sx={
          fromDialog
            ? { position: 'absolute', zIndex: 100, p: 1 }
            : entry.viewType !== 'pages'
            ? {
                borderBottom: `2px solid ${grey[300]}`,
                pb: 1,
              }
            : undefined
        }
      >
        <Grid container item alignItems="center" spacing={fromDialog ? 0 : 1}>
          <Grid container item lg={5} md={5} sm={12} alignItems="center" wrap="nowrap">
            <BackArrowButton onClick={goBack} />

            <Grid item style={{ position: 'relative', maxWidth: '85%' }}>
              <TitleTypography
                item={entry}
                variant={largeDevices ? 'h4' : 'h6'}
                style={{ marginLeft: 8, position: 'relative' }}
                number={entry.formNumber}
              />
              {entry.testModeId && (
                <Chip
                  label={'Test'}
                  size="small"
                  style={{
                    background: deepOrange[700],
                    color: 'white',
                    position: 'absolute',
                    right: -32,
                    top: -16,
                    zIndex: 100,
                  }}
                />
              )}
            </Grid>
          </Grid>

          <Grid container item lg={7} md={7} sm={12} alignItems="center" justifyContent="flex-end">
            {entry.state === 'editing' && (
              <Chip label="Editing" sx={{ background: deepOrange[500], color: 'white', mr: 2 }} />
            )}
            {entry.state === 'upissuing' && (
              <Chip label="Up-issuing" sx={{ background: deepOrange[500], color: 'white', mr: 2 }} />
            )}
            <Chip label={`v${entry.version}`} sx={{ background: deepPurple[500], color: 'white', mr: 2 }} />
            <Chip
              label={`#${addZeros(Number(entry.number))}${
                entry.entryVersion ? `-${formatEntryVersion(entry.entryVersionFormat, entry.entryVersion)}` : ''
              }`}
              sx={{ background: teal[500], color: 'white', mr: 2 }}
            />

            {upissuing ? (
              <UserGroup avatarSize={32} ids={entry.editingUsers} onlyAvatar={!largeDevices} />
            ) : (
              <UserGroup
                avatarSize={32}
                ids={entry.assignedUsers}
                dialogTitle="Assigned users"
                onlyAvatar={!largeDevices}
              />
            )}

            <Tooltip title="Info">
              <IconButton onClick={() => setEntryInfoDialog(true)}>
                <InfoOutlined style={{ color: iconColor }} />
              </IconButton>
            </Tooltip>
            <EntryInfoDialog
              open={entryInfoDialog}
              onClose={() => setEntryInfoDialog(false)}
              entry={entry}
              user={user}
              onChange={(res) => {
                setEntry({ ...entry, ...res });
              }}
              onDelete={goBack}
            />

            {(upissuingByUser || (upissuing && userAdmin)) && (
              <Tooltip title="Cancel up-issue">
                <IconButton onClick={cancelEdit}>
                  {cancellingEdit ? (
                    <CircularProgress size={24} />
                  ) : (
                    <HighlightOffRounded style={{ color: red[500] }} />
                  )}
                </IconButton>
              </Tooltip>
            )}

            {!entry.completedAt &&
              !upissuing &&
              (user.access === 'admin' || user._id === entry.startedBy || entry.formOwners?.includes(user._id)) && (
                <>
                  <Tooltip title="Delete entry">
                    <IconButton onClick={() => setDeleteDialog(true)}>
                      <DeleteOutlineRounded style={{ color: red[500] }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel entry">
                    <IconButton onClick={() => setCancelDialog(true)}>
                      <HighlightOffRounded style={{ color: red[500] }} />
                    </IconButton>
                  </Tooltip>

                  <CancelDialog
                    open={cancelDialog}
                    onClose={() => setCancelDialog(false)}
                    title={'You are about to cancel this entry'}
                    onResult={cancelEntry}
                  />
                  <DeleteEntryDialog
                    open={deleteDialog}
                    onClose={() => setDeleteDialog(false)}
                    entry={entry}
                    onResult={() => {
                      isFunction(onFormDelete) && onFormDelete(entry._id);
                      goBack();
                    }}
                  />
                </>
              )}

            {!editable && !upissuing && entry.editable && isEmpty(reviews) && (
              <Tooltip title={entry.entriesControlled ? 'Up-issue entry' : 'Edit entry'}>
                <IconButton onClick={startEdit} style={{ color: iconColor }}>
                  {startingEditing ? <CircularProgress size={24} /> : <EditRounded />}
                </IconButton>
              </Tooltip>
            )}
            {(user._id === entry.startedBy || entry.formOwners?.includes(user._id)) && (
              <>
                <Tooltip title="Reminder">
                  <IconButton onClick={(e) => setReminderDialog(true)}>
                    <Badge
                      badgeContent={(entry.reminder?.history?.length || 0) + (entry.reminderHistory?.length || 0)}
                      color="primary"
                      invisible={(entry.reminder?.history?.length || 0) + (entry.reminderHistory?.length || 0) === 0}
                    >
                      {entry.reminder?.active || entry.completedAt ? (
                        <AlarmOnRounded style={{ color: iconColor }} />
                      ) : (
                        <AlarmOffRounded style={{ color: iconColor }} />
                      )}
                    </Badge>
                  </IconButton>
                </Tooltip>
                <ReminderDialog
                  open={reminderDialog}
                  onClose={() => setReminderDialog(false)}
                  entry={entry}
                  setEntry={setEntry}
                />
              </>
            )}
            <Tooltip title="Export to PDF">
              <IconButton onClick={downloadPDF}>
                {downloading ? (
                  <CircularProgress color="primary" size={24} />
                ) : (
                  <GetAppRounded style={{ color: iconColor }} />
                )}
              </IconButton>
            </Tooltip>
            {entry.completedAt && (
              <>
                <Tooltip title="Email entry">
                  <IconButton onClick={() => setEmailDialog(true)}>
                    <MailOutlineRounded style={{ color: iconColor }} />
                  </IconButton>
                </Tooltip>
                <EmailDialog open={emailDialog} onClose={() => setEmailDialog(false)} entryId={entry._id} />
              </>
            )}
            {entry.entryVersion && (
              <>
                <Tooltip title="Version history">
                  <IconButton onClick={() => setVersionsDialog(true)}>
                    <HistoryRounded style={{ color: iconColor }} />
                  </IconButton>
                </Tooltip>

                <EntryVersionsDialog
                  open={versionsDialog}
                  onClose={() => setVersionsDialog(false)}
                  entryId={entry._id}
                />
              </>
            )}

            {entry.completedAt && (entry.settings?.reviewEntries || !isEmpty(reviews)) && (
              <>
                <Tooltip title="Reviews">
                  <IconButton onClick={() => setReviewDialog(true)}>
                    <LibraryBooksRounded style={{ color: iconColor }} />
                  </IconButton>
                </Tooltip>

                <ReviewDialog
                  open={reviewDialog}
                  onClose={() => setReviewDialog(false)}
                  type="formEntry"
                  targetId={entry?._id}
                  onEntryCreated={(review) => {
                    setReviews([...reviews, review]);
                    setCurrSectionIndex(shownSection.length - 1);
                    setTimeout(() => highlightElement(review._id), 300);
                  }}
                  closeOnEntryCreated
                />
              </>
            )}

            <TextDialog
              open={upIssueDialog}
              onClose={() => setUpIssueDialog(false)}
              required
              editable
              title={'Please provide reason for up issue'}
              onResult={(reason) => finishEdit(reason)}
              onlyText
            />
          </Grid>
        </Grid>

        {entry.dueAt && !entry.completedAt && (
          <Grid container item sx={{ py: 1 }} alignItems="center">
            <Typography style={{ fontWeight: formOverdue ? 500 : 400, color: formOverdue ? red[600] : undefined }}>
              The form is due {FormatDate(entry.dueAt)}
            </Typography>
          </Grid>
        )}

        {entry.cancelledAt && (
          <Grid container item direction={'column'} sx={{ mt: 1 }}>
            <Typography variant="h6" color="secondary" gutterBottom>
              This entry has been cancelled
            </Typography>

            <Typography style={{ whiteSpace: 'break-spaces' }}>Reason: {entry.cancelReason}</Typography>
          </Grid>
        )}

        {entry.archivedAt && (
          <Grid
            container
            item
            sx={{
              borderTop: `2px solid ${grey[200]}`,
              my: 1,
              pt: 1,
            }}
            alignItems={'center'}
          >
            <Typography variant="h6">The entry was archived on {FormatOnlyDate(entry.archivedAt)}</Typography>
            {(userAdmin || entry.formOwners?.includes(user?._id)) && (
              <Button
                sx={{ ml: 2 }}
                startIcon={unarchiving ? <CircularProgress size={24} /> : <UnarchiveRounded />}
                onClick={() => unarchive()}
              >
                unarchive
              </Button>
            )}
          </Grid>
        )}

        {entry.completedAt && !entry.cancelledAt && (
          <Grid container item sx={{ py: 1 }} alignItems="center">
            <Typography>{`Submitted ${FormatDate(entry.completedAt)} by`}</Typography>
            <User onlyAvatar={!largeDevices} style={{ marginLeft: '1rem' }} id={entry.completedBy} avatarSize={32} />
          </Grid>
        )}
        {!entry.completedAt && entry.submittedForApprovalAt && entry.submittedForApprovalBy && !entry.upIssueReason && (
          <Grid container item sx={{ py: 1 }} alignItems="center">
            <Typography>{`Submitted for approval ${FormatDate(entry.submittedForApprovalAt)} by`}</Typography>
            <User
              onlyAvatar={!largeDevices}
              style={{ marginLeft: '1rem' }}
              id={entry.submittedForApprovalBy}
              avatarSize={32}
            />
          </Grid>
        )}
        {entry.viewType === 'pages' && shownSection.length > 1 && (
          <LinearProgress variant="determinate" value={progress} style={{ width: '100%' }} />
        )}
      </Grid>

      <Grid
        container
        item
        sx={{ flex: 1, overflow: 'auto', mt: fromDialog ? '120px' : 0 }}
        alignItems="baseline"
        alignContent="flex-start"
      >
        <Grid container item alignItems="baseline">
          {entry.viewType === 'pages' ? (
            <Grid container item sx={{ py: 1 }} justifyContent="center" alignContent="flex-start">
              <SwipeableViews
                index={currSectionIndex}
                onChangeIndex={setCurrSectionIndex}
                style={{ width: '100%' }}
                slideStyle={{ width: '100%', padding: theme.spacing(0, 2) }}
                disableLazyLoading
                disabled
              >
                {shownSection.map((section, i) => (
                  <TabPanel key={i} value={currSectionIndex} index={i} justifyContent="center">
                    {currSectionIndex === i && (
                      <Grid key={section.id} container item {...containerPrors}>
                        {i === 0 && StartInfo}

                        <Section
                          entry={entry}
                          section={section}
                          editable={editable}
                          hiddenItems={hiddenItems}
                          onChange={onSectionUpdate}
                          onCalculationUpdate={onCalculationUpdate}
                          onHiddenUpdate={onHiddenUpdate}
                          allCreatedActions={allCreatedActions}
                          executedActions={executedActions}
                          onExecutedActionDelete={deleteActionById}
                        />
                        {lastSection && ApprovalSection}
                        {lastSection && Attachments}
                        {lastSection && Actions}
                        {lastSection && Reviews}
                        {lastSection && entry.completedAt && ActionsOnComplete}

                        {shownSection.length > 1 && (
                          <Grid container item sx={{ py: 1, mb: 5 }}>
                            {currSectionIndex !== 0 && (
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{ borderRadius: 8 }}
                                onClick={() => setCurrSectionIndex(currSectionIndex - 1)}
                                startIcon={<ChevronLeftRounded />}
                              >
                                Back
                              </Button>
                            )}

                            {!lastSection && (
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ borderRadius: 8, marginLeft: 'auto' }}
                                onClick={
                                  editable
                                    ? () => checkEntrySection('next')
                                    : () => setCurrSectionIndex(currSectionIndex + 1)
                                }
                                endIcon={
                                  checkingSection === 'next' ? (
                                    <CircularProgress size={24} color="primary" />
                                  ) : (
                                    <ChevronRightRounded />
                                  )
                                }
                              >
                                Next
                              </Button>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </TabPanel>
                ))}
              </SwipeableViews>
            </Grid>
          ) : (
            <Grid container item sx={{ py: 1 }} justifyContent="center">
              {shownSection.map((section, i) => (
                <Grid container item {...containerPrors} key={section.id}>
                  {i === 0 && StartInfo}
                  <Section
                    entry={entry}
                    section={section}
                    hiddenItems={hiddenItems}
                    editable={editable}
                    onCalculationUpdate={onCalculationUpdate}
                    onHiddenUpdate={onHiddenUpdate}
                    allCreatedActions={allCreatedActions}
                    onCreatedAction={onCreatedAction}
                    onDeletedAction={onDeletedAction}
                    onChange={onSectionUpdate}
                    executedActions={executedActions}
                    onExecutedActionDelete={deleteActionById}
                  />
                </Grid>
              ))}
              <Grid container item sx={{ py: 1 }} justifyContent="center">
                <Grid container item {...containerPrors}>
                  {ApprovalSection}
                </Grid>
              </Grid>

              <Grid container item sx={{ py: 1 }} justifyContent="center">
                <Grid container item {...containerPrors}>
                  {Reviews}
                </Grid>
              </Grid>

              <Grid container item sx={{ py: 1 }} justifyContent="center">
                <Grid container item {...containerPrors}>
                  {Attachments}
                </Grid>
              </Grid>

              <Grid container item sx={{ py: 1 }} justifyContent="center">
                <Grid container item {...containerPrors}>
                  {Actions}
                </Grid>
              </Grid>

              {entry.completedAt && ActionsOnComplete}
            </Grid>
          )}
        </Grid>

        {editable && (entry.viewType !== 'pages' || lastSection) && (
          <Grid item style={{ position: fromDialog ? 'absolute' : 'fixed', bottom: largeDevices ? 32 : 64, right: 32 }}>
            {upissuing ? (
              <Fab variant="extended" color="primary" onClick={checkUpIssue}>
                {submiting ? (
                  <CircularProgress color="primary" style={{ color: 'white' }} size={24} />
                ) : (
                  <CheckCircleOutlineRounded />
                )}
                <div style={{ marginLeft: 8 }} />
                {entry.entriesControlled && entry.state === 'upissuing'
                  ? approvalNeeded
                    ? 'Submit for approval'
                    : 'Finish up-issue'
                  : 'Finish edit'}
              </Fab>
            ) : (
              <Fab variant="extended" color="primary" onClick={checkToSubmit}>
                {submiting ? (
                  <CircularProgress color="primary" style={{ color: 'white' }} size={24} />
                ) : (
                  <CheckCircleOutlineRounded />
                )}
                <div style={{ marginLeft: 8 }} />
                {approvalNeeded ? 'Submit for approval' : 'Submit form'}
              </Fab>
            )}
          </Grid>
        )}
      </Grid>

      {hasCompleteActions && (
        <FormActionsDialog
          open={actionsDialog}
          onClose={() => setActionsDialog(false)}
          actions={entry.completeActions}
          onResult={submitForm}
        />
      )}
    </PageContainer>
  );
}

export function Section({
  entry,
  section,
  editable,
  hiddenItems,
  onChange,
  autoSave = true,
  onCalculationUpdate,
  onHiddenUpdate,
  allCreatedActions,
  onCreatedAction,
  onDeletedAction,
  executedActions,
  onExecutedActionDelete,
}) {
  const [saving, setSaving] = useState('');
  const [items, setItems] = useState([]);
  const [savedAt, setSavedAt] = useState(null);
  const [tableDialogOpen, setTableDialogOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const defaultDueDate = moment().add(1, 'week').set({ minutes: 0, seconds: 0, milliseconds: 0 });

  useEffect(() => {
    if (isArray(section?.items)) {
      setItems(section.items);
    }
    if (isArray(section.table?.rows)) {
      setRows(section.table.rows);
    }
  }, [section]);

  function onSave(fieldId, value) {
    const body = {
      entryId: entry._id,
      sectionId: section.id,
      fieldId,
      value,
    };

    if (_.isFunction(onChange)) onChange(body);

    if (!autoSave) {
      return;
    }

    setSaving('Saving...');
    setSavedAt(null);

    axios
      .post('/forms/entries/saveEntryField', body)
      .then(({ data }) => {
        const index = items.findIndex((x) => x.id === data.fieldId);
        if (index > -1) {
          items[index].userId = data.userId;
          items[index].editedAt = data.editedAt;
          items[index].filledValue = value;
        }

        if (!_.isEmpty(data.calculated) && _.isArray(data.calculated)) {
          onCalculationUpdate(data.calculated);
        }

        onHiddenUpdate(data);

        setSaving('Saved');
        setSavedAt(new Date());
      })
      .catch(() => {
        setSaving('Failed to save the value');
        StateManager.setErrorAlert('Failed to save the value');
      });
  }

  function onRowsSave(rows) {
    const body = {
      entryId: entry._id,
      sectionId: section.id,
      rows,
    };
    if (typeof onChange === 'function') onChange(body);

    if (!autoSave) {
      return;
    }

    setSaving('Saving...');

    axios
      .post('/forms/entries/saveEntryTable', body)
      .then((res) => {
        setRows(res.data.updatedRows);
        setSaving('Saved');
      })
      .catch(() => {
        setSaving('Failed to save the table');
        StateManager.setErrorAlert('Failed to save the table');
      });
  }

  const itemsToRender = useMemo(
    () => items.filter((x) => !x.conditionalFieldId).filter(({ id }) => !hiddenItems?.includes(id)),
    [items, hiddenItems],
  );

  if (!section) return null;

  return (
    <SectionContainer container item>
      <Grid container component={SectionPaper} elevation={3}>
        {section.title && (
          <Grid container item sx={{ py: 1 }} alignItems="center">
            <Typography variant="h5">{section.title}</Typography>
            {editable && (
              <FromNow
                prefix={saving}
                value={savedAt}
                color="textSecondary"
                style={{ fontSize: '0.75rem', marginLeft: 'auto' }}
              />
            )}
          </Grid>
        )}

        <Grid container item sx={{ py: 1 }}>
          {section.type !== 'grid' &&
            itemsToRender.map((item) => (
              <Field
                key={item.id}
                item={item}
                editable={editable && !item.filledValue?.locked}
                onSave={onSave}
                allItems={items}
                assignedUsers={entry.assignedUsers}
                dueDate={defaultDueDate}
                activityId={entry._id}
                assignedBy={entry.startedBy}
                hiddenItems={hiddenItems}
                activity={'FormEntry'}
                executedActions={executedActions}
                onExecutedActionDelete={onExecutedActionDelete}
                allCreatedActions={allCreatedActions}
                onCreatedAction={onCreatedAction}
                onDeletedAction={onDeletedAction}
                activityInfo={{
                  type: 'form',
                  entryId: entry._id,
                  sectionId: section.id,
                }}
              />
            ))}
          {section.type === 'table' && _.isArray(section.table?.columns) && !_.isEmpty(section.table.columns) && (
            <>
              <FieldDataGrid
                columns={section.table.columns}
                initial={rows}
                editable={editable}
                onChange={onRowsSave}
                allCreatedActions={allCreatedActions}
                onCreatedAction={onCreatedAction}
                onDeletedAction={onDeletedAction}
                forceUpdate={tableDialogOpen}
                activityInfo={{
                  type: 'form',
                  entryId: entry._id,
                  sectionId: section.id,
                }}
              />
              <Grid container justifyContent="flex-end" sx={{ mt: 1 }}>
                <Button
                  startIcon={<SettingsOverscanRounded />}
                  onClick={() => setTableDialogOpen(true)}
                  style={{ color: grey[700] }}
                >
                  open in dialog
                </Button>
              </Grid>
              <TableDialog
                title={section.title}
                columns={section.table.columns}
                rows={rows}
                editable={editable}
                onChange={(data) => {
                  onRowsSave(data);
                }}
                open={tableDialogOpen}
                onClose={() => setTableDialogOpen(false)}
                activityInfo={{
                  type: 'form',
                  entryId: entry._id,
                  sectionId: section.id,
                }}
                onCreatedAction={onCreatedAction}
              />
            </>
          )}
          {section.type === 'info' && section.info && (
            <Grid container>
              <Grid
                container
                item
                lg={section.info.layout === 'textAndImages' && section.info.images[0] ? 8 : 12}
                md={section.info.layout === 'textAndImages' && section.info.images[0] ? 8 : 12}
                xs={12}
                style={{ paddingRight: 8 }}
              >
                <HtmlContent content={section.info.text} />
              </Grid>
              {section.info.layout === 'textAndImages' && section.info.images[0] && (
                <Grid container item lg={4} md={4} xs={12} alignContent="flex-start">
                  <Grid container item>
                    <PicturesCarousel pictures={section.info.images} />
                  </Grid>
                  <Grid container item style={{ marginTop: 8 }}>
                    <Typography variant="h6">{section.info.caption}</Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {section.type === 'grid' && (
            <GridSection layout={section.layout} items={section.items} editable={editable} onSave={onSave} />
          )}
        </Grid>
        {!section.title && editable && (
          <Grid container item alignItems="center">
            <FromNow
              prefix={saving}
              value={savedAt}
              color="textSecondary"
              style={{ fontSize: '0.75rem', marginLeft: 'auto' }}
            />
          </Grid>
        )}
      </Grid>
    </SectionContainer>
  );
}

function TableDialog({
  title,
  columns,
  rows,
  editable,
  onChange,
  open,
  onClose,
  activityInfo,
  allCreatedActions,
  onCreatedAction,
}) {
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <FieldDataGrid
          columns={columns}
          initial={rows}
          editable={editable}
          onChange={onChange}
          activityInfo={activityInfo}
          allCreatedActions={allCreatedActions}
          onCreatedAction={onCreatedAction}
          forDialog
        />
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={onClose} hideDone={!editable} />
    </RoundedDialog>
  );
}

function ReminderDialog({ entry, setEntry, open, onClose }) {
  const [switchingReminder, setSwitchingReminder] = useState(false);
  const [sending, setSending] = useState(false);
  const sentReminders = entry
    ? _.orderBy(
        [
          ...(_.isArray(entry.reminderHistory) ? entry.reminderHistory : []),
          ...(_.isArray(entry.reminder?.history) ? entry.reminder.history : []),
        ],
        ['sentAt'],
        ['desc'],
      )
    : [];

  function sendReminder() {
    if (sending) return;
    setSending(true);
    axios
      .post('/forms/entries/sendFormReminder', { entryId: entry?._id })
      .then((res) => {
        entry.reminderHistory = _.isArray(entry.reminderHistory) ? [...entry.reminderHistory, res.data] : [res.data];
        setSending(false);
        StateManager.setSuccessAlert('Reminder has been sent');
      })
      .catch((err) => {
        setSending(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function switchReminder() {
    if (!entry?.reminder?._id || switchingReminder) return;

    setSwitchingReminder(true);

    axios
      .post('/forms/invitations/switchReminder', { reminderId: entry.reminder._id })
      .then((res) => {
        setSwitchingReminder(false);
        StateManager.setSuccessAlert(`Reminder has been ${res.data.active ? 'enabled' : 'disabled'}`);
        entry.reminder.active = res.data.active;
        setEntry({ ...entry });
        onClose();
      })
      .catch((err) => {
        setSwitchingReminder(false);
        StateManager.setAxiosErrorAlert(err);
        onClose();
      });
  }

  if (!entry) return null;

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Form reminders</DialogTitle>
      <DialogContent>
        <Grid container item direction="column">
          <Grid container item direction="column">
            {_.isArray(sentReminders) &&
              (_.isEmpty(sentReminders) ? (
                <Typography gutterBottom color="textSecondary">
                  No reminders have been sent
                </Typography>
              ) : (
                <>
                  <Typography gutterBottom>
                    {sentReminders.length} reminder{sentReminders.length === 1 ? '' : 's'} sent:
                  </Typography>
                  {sentReminders.map((item) => (
                    <Typography key={item._id} gutterBottom>
                      {FormatDateFull(item.sentAt)}
                    </Typography>
                  ))}
                </>
              ))}
          </Grid>

          {!entry.completedAt && (
            <Grid container item style={{ marginTop: 12 }}>
              <Button
                startIcon={sending ? <CircularProgress size={24} color="primary" /> : <NotificationsActiveRounded />}
                onClick={sendReminder}
                variant="outlined"
                color="primary"
                style={{ borderRadius: 8 }}
              >
                send reminder
              </Button>
            </Grid>
          )}
        </Grid>
        {entry.reminder && !entry.completedAt && (
          <Grid container direction="column" style={{ marginTop: 16 }}>
            {entry.reminder.active ? (
              <Grid container item>
                <Typography gutterBottom style={{ color: entry.reminder.active ? undefined : grey[400] }}>
                  Reminder will be emailed every {entry.reminder.interval}{' '}
                  {entry.reminder.unit === 'days'
                    ? entry.reminder.interval === 1
                      ? 'day'
                      : 'days'
                    : entry.reminder.interval === 1
                    ? 'week'
                    : 'weeks'}{' '}
                  until the entry is completed {entry.dueAt ? 'afrer form is overdue' : ''}
                </Typography>
              </Grid>
            ) : (
              <Grid container item>
                <Typography gutterBottom>Automatic reminder is disabled</Typography>
              </Grid>
            )}
            <Grid container item style={{ marginTop: 12 }}>
              <Button
                startIcon={entry.reminder.active ? <AlarmOffRounded /> : <AlarmAddRounded />}
                onClick={switchReminder}
                variant="outlined"
                color="primary"
                style={{ borderRadius: 8 }}
              >
                {`${entry.reminder.active ? 'disable' : 'enable'} reminder`}
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions saving={switchingReminder} onClose={onClose} />
    </RoundedDialog>
  );
}

function ActionButton({ action, parentFormEntryId }) {
  const [starting, setStarting] = useState(false);
  const [optionsDialog, setOptionsDialog] = useState(false);
  const history = useHistory();

  function checkProcessUsers() {
    const selectable =
      (action.userType === 'selectedUsers' && action.provideUserChoice && !_.isEmpty(action.users)) ||
      action.startAnytime;

    if (selectable) {
      setOptionsDialog(true);
    } else {
      startProcess();
    }
  }

  function checkFormUsers() {
    const selectable =
      (action.userType === 'selectedUsers' && action.provideUserChoice && !_.isEmpty(action.users)) ||
      action.startAnytime;

    if (selectable) {
      setOptionsDialog(true);
    } else {
      startForm();
    }
  }

  function startProcess(params) {
    if (!action?.processId) return;
    setStarting(true);
    const dueAt = params
      ? params.dueAt
      : action.due
      ? moment().add(action.due.hours, 'hours').add(action.due.days, 'days').toDate()
      : null;

    const body = {
      processId: action.processId,
      parentFormEntryId,
      assignedUsers: params
        ? params.selectedUsers
        : action.userType === 'selectedUsers' && !_.isEmpty(action.users)
        ? action.users
        : null,
      dueAt,
      formActionId: action.id,
    };

    axios
      .post('/process/startProcess', body)
      .then((res) => {
        StateManager.setSuccessAlert('Process has been started');
        setStarting(false);
        history.push(`/processes/ongoing/${res.data.ongoingProcess._id}`);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setStarting(false);
      });
  }

  function startForm(params) {
    if (!action?.formId) return;
    setStarting(true);

    const dueAt = params
      ? params.dueAt
      : action.due
      ? moment().add(action.due.hours, 'hours').add(action.due.days, 'days').toDate()
      : null;

    const body = {
      formId: action.formId,
      parentFormEntryId,
      assignedUsers: params
        ? params.selectedUsers
        : action.userType === 'selectedUsers' && !_.isEmpty(action.users)
        ? action.users
        : null,
      dueAt,
      formActionId: action.id,
    };

    axios
      .post('/forms/entries/sendEntry', body)
      .then((res) => {
        StateManager.setSuccessAlert('Process has been started');
        setStarting(false);
        history.push(`/forms/entry/${res.data[0]._id}`);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setStarting(false);
      });
  }

  if (!action) return null;

  if (action.actionType === 'startProcess') {
    return (
      <ReferenceItem container justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: 'none', borderRadius: 8 }}
          startIcon={<TimelineRounded />}
          endIcon={starting ? <CircularProgress size={24} style={{ color: 'white' }} /> : null}
          onClick={checkProcessUsers}
        >
          Start process: {action.processTitle}
        </Button>
        <OptionsDialog
          action={action}
          open={optionsDialog}
          onClose={() => setOptionsDialog(false)}
          onResult={startProcess}
        />
      </ReferenceItem>
    );
  }

  if (action.actionType === 'startForm') {
    return (
      <ReferenceItem container justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: 'none', borderRadius: 8 }}
          startIcon={<DescriptionRounded />}
          endIcon={starting ? <CircularProgress size={24} style={{ color: 'white' }} /> : null}
          onClick={checkFormUsers}
        >
          Start form: {action.formTitle}
        </Button>
        <OptionsDialog
          action={action}
          open={optionsDialog}
          onClose={() => setOptionsDialog(false)}
          onResult={startForm}
        />
      </ReferenceItem>
    );
  }

  return null;
}

function OptionsDialog({ action, open, onClose, onResult }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [includeDueDate, setIncludeDueDate] = useState(false);
  const [dueAt, setDueAt] = useState(null);

  const defaultDue = action.due
    ? moment().add(action.due.hours, 'hours').add(action.due.days, 'days').toDate()
    : moment().add(7, 'days').set({ hours: 17, minutes: 0, seconds: 0, milliseconds: 0 });

  useEffect(() => {
    if (action?.due) {
      setIncludeDueDate(true);
      setDueAt(defaultDue);
    }
  }, [action]); // eslint-disable-line

  const choseUsers =
    action.userType === 'selectedUsers' &&
    !_.isEmpty(action.users) &&
    _.isArray(action.users) &&
    action.provideUserChoice;
  const choseDue = action.startAnytime;

  const hideDone = choseUsers && _.isEmpty(selectedUsers);

  function done() {
    const result = { selectedUsers, dueAt: includeDueDate ? (dueAt ? moment(dueAt).toDate() : null) : null };
    onResult(result);
    onClose();
  }

  if (!action) return null;

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <Grid container item style={{ margin: '1rem 0' }} wrap="nowrap" alignItems="center">
          {action.actionType === 'startProcess' && <ProcessIcon />}
          {action.actionType === 'startForm' && <FormIcon />}
          {action.actionType === 'sendTask' && <TaskIcon />}
          <Typography style={{ marginLeft: 8, whiteSpace: 'break-spaces' }}>
            You are about to{' '}
            {action.actionType === 'startProcess' && (
              <>
                start process: {'\n'}
                <span style={{ fontWeight: 500 }}>{action.processTitle}</span>
              </>
            )}
            {action.actionType === 'startForm' && (
              <>
                start form entry: {'\n'}
                <span style={{ fontWeight: 500 }}>{action.formTitle}</span>
              </>
            )}
            {action.actionType === 'sendTask' && (
              <>
                send task: {'\n'}
                <span style={{ fontWeight: 500 }}>{action.task?.title}</span>
              </>
            )}
          </Typography>
        </Grid>
        {choseUsers && (
          <Grid container item style={{ margin: '1rem 0' }}>
            <Grid container item>
              <Typography variant="h6" gutterBottom>
                Pick users:
              </Typography>
            </Grid>

            <Grid container item>
              {action.users.map((user, i) => (
                <User
                  key={i}
                  id={user}
                  fullWidth
                  markPortal
                  onClick={() => {
                    if (selectedUsers.includes(user)) {
                      setSelectedUsers(selectedUsers.filter((x) => x !== user));
                    } else {
                      setSelectedUsers([...selectedUsers, user]);
                    }
                  }}
                  action={
                    selectedUsers.includes(user) ? (
                      <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                    ) : (
                      <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                    )
                  }
                />
              ))}
            </Grid>
          </Grid>
        )}

        {choseDue && (
          <Grid container>
            <Grid container>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={includeDueDate}
                    onChange={(e) => {
                      setIncludeDueDate(e.target.checked);
                      if (e.target.checked && !dueAt) {
                        setDueAt(defaultDue);
                      }
                    }}
                  />
                }
                label="Include due date"
              />
            </Grid>
            <Collapse unmountOnExit style={{ width: '100%' }} in={includeDueDate}>
              <Grid container>
                <Grid item container xs={6} style={{ paddingRight: 4 }}>
                  <DatePicker value={dueAt} onChange={setDueAt} />
                </Grid>

                <Grid item container xs={6} style={{ paddingLeft: 4 }}>
                  <TimePicker value={dueAt} onChange={setDueAt} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} hideDone={hideDone} />
    </RoundedDialog>
  );
}
